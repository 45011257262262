import React, { useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { usePageData } from 'hooks';
import { useQueryParam, BooleanParam, StringParam } from 'use-query-params';
import { useRouter } from 'next/router';
import i18n from 'utils/i18n';
import { DropDown } from 'components/Shared';

const Container = styled.div``;

const DropDownItem = styled.div`
  display: flex;

  img {
    width: 20px;
    height: 100%;
    margin-right: 8px;
  }
`;

const config = {
  en: {
    title: 'English',
    label: 'En',
    value: 'en',
    flag: '/flags/gb.svg',
  },
  de: {
    title: 'German',
    label: 'De',
    value: 'de',
    flag: '/flags/de.svg',
  },
  fr: {
    title: 'French',
    label: 'Fr',
    value: 'fr',
    flag: '/flags/fr.svg',
  },
  nl: {
    title: 'Dutch',
    label: 'Nl',
    value: 'nl',
    flag: '/flags/nl.svg',
  },
  es: {
    title: 'Spanish',
    label: 'Es',
    value: 'es',
    flag: '/flags/es.svg',
  },
};

interface Props {
  className?: string;
}

const LanguageSwitch: React.FC<Props> = () => {
  const [preview] = useQueryParam('preview', BooleanParam);
  const {
    languageConfig: { enabled },
    publishedLanguages,
    currentLang,
  } = usePageData();

  const [locale = currentLang, setLocale] = useQueryParam(
    'locale',
    StringParam
  );
  const prevLang = useRef();
  const langs = preview ? enabled : publishedLanguages;
  const options = useMemo(() => langs.map((key) => config[key]), [langs]);
  // if locale param is not available set language to currently returned language
  const value = useMemo(
    () =>
      options.find(({ value }) => value === locale) ||
      options.find(({ value }) => value === currentLang),
    [locale, options]
  );
  const router = useRouter();

  useEffect(() => {
    if (!prevLang.current) {
      // @ts-expect-error
      prevLang.current = locale;
      return;
    }

    router.replace(router.asPath);
    // @ts-expect-error
    prevLang.current = locale;
  }, [locale]);

  useEffect(() => {
    i18n.changeLanguage(currentLang);
  }, [currentLang]);

  if (langs.length <= 1) return null;

  return (
    <Container>
      <DropDown
        currentValue={value}
        options={options}
        onChange={({ value }) => setLocale(value)}
        renderItems={(option) => (
          <DropDownItem>
            <img src={option.flag} alt={`${option.title} flag`} />
            {option.value}
          </DropDownItem>
        )}
      />
    </Container>
  );
};

export default LanguageSwitch;
