import { BREAKPOINTS } from 'constants/breakpoints';
import React, { ReactChild, useEffect, useState } from 'react';
import useWindowSize from 'hooks/useWindowSize';

export interface BrowserContextReturn {
  isMobile: boolean;
  isSafari: boolean;
}

interface Props {
  children: ReactChild | ReactChild[];
  isMobileServerSide: boolean | undefined;
  isSafariServerSide: boolean | undefined;
}

const BrowserContext = React.createContext<BrowserContextReturn>({
  isMobile: true,
  isSafari: false,
});
const BrowserConsumer = BrowserContext.Consumer;

/**
 * This context is here to store the Server Side value of checking if the device is mobile or not and if the browser is Safari or not.
 */

const BrowserProvider: React.FC<Props> = ({
  children,
  isMobileServerSide,
  isSafariServerSide,
}: Props) => {
  const [isMobile, setIsMobile] = useState<boolean>(true);
  const [isSafari, setIsSafari] = useState<boolean>(false);

  const { width } = useWindowSize();

  useEffect(() => {
    const newIsMobile =
      isMobileServerSide !== undefined
        ? String(isMobileServerSide) === 'true'
        : width < BREAKPOINTS.mobile;
    if (isMobile !== newIsMobile) {
      setIsMobile(newIsMobile);
    }
  }, [isMobileServerSide, width]);

  useEffect(() => {
    const newIsSafari =
      isSafariServerSide !== undefined
        ? String(isSafariServerSide) === 'true'
        : false;
    if (isSafari !== newIsSafari) {
      setIsSafari(newIsSafari);
    }
  }, [isSafariServerSide]);

  return (
    <BrowserContext.Provider value={{ isMobile, isSafari }}>
      {children}
    </BrowserContext.Provider>
  );
};

export { BrowserProvider, BrowserConsumer };

export default BrowserContext;
