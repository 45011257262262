import React, { useEffect, useState } from 'react';
import { ImageType, ImageVariant, AnimatedPin, PinTarget } from 'types/types';
import styled, { keyframes, css } from 'styled-components';
import { useTheme } from 'styled-components';
import Image from 'components/Shared/Image';

const bouncing = keyframes`
  from {  transform: translateY(0, 0, 0); }
  to   {  transform: translate3d(0, -20%, 0); }
`;

const bouncingOnNode = keyframes`
  from {  transform: translate3d(0, 0, 0) scale(0.7) }
  to   {  transform: translate3d(0, -10px, 0) scale(0.7) }
`;

const Container = styled.div<{ bounce: boolean; renderedOnNode: boolean }>`
  height: 50px;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.15));

  ${({ bounce, renderedOnNode }) =>
    bounce &&
    css`
      animation: ${renderedOnNode ? bouncingOnNode : bouncing} 0.4s;
      animation-direction: alternate;
      animation-iteration-count: 8;
    `}
`;

const LocationImage = styled(Image)`
  position: absolute !important;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);

  img {
    object-fit: cover;
    height: 100% !important;
    vertical-align: inherit !important;
  }
`;

interface Props {
  image?: ImageVariant | ImageType;
  className?: string;
  active?: boolean;
  color?: string;
  renderedOn?: string;
  animatedPin?: AnimatedPin;
  activeNodeId?: string;
  hasBounceEffect?: boolean;
}

const Marker: React.FC<Props> = ({
  image,
  active,
  className = '',
  color,
  renderedOn,
  animatedPin,
  activeNodeId,
  hasBounceEffect = true,
}: Props) => {
  const { palette } = useTheme();
  const defaultColor = active ? palette.primary.main : '#c4c4c4';
  const fill = color ? color : defaultColor;
  const [bouncing, setBouncing] = useState<boolean>(false);

  useEffect(() => {
    if (
      animatedPin?.id === activeNodeId &&
      renderedOn === PinTarget.MAP &&
      active &&
      hasBounceEffect
    ) {
      setBouncing(true);
    }
  }, [animatedPin, renderedOn]);

  return (
    <Container
      className={className}
      bounce={bouncing}
      onAnimationEnd={() => setBouncing(false)}
      renderedOnNode={renderedOn === PinTarget.NODE}
    >
      <svg
        width="40"
        height="50"
        viewBox="0 0 66 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="matrix(1.58593,0,0,1.58593,0,0)">
          <path
            d="M41,20.5C41,20.627 40.999,20.753 40.997,20.88L41,20.88C41,42.519 20.5,51.63 20.5,51.63C20.5,51.63 0,42.519 0,20.88L0.003,20.88C0.001,20.753 0,20.627 0,20.5C0,9.178 9.178,0 20.5,0C31.822,0 41,9.178 41,20.5ZM20.5,34.926C28.467,34.926 34.926,28.467 34.926,20.5C34.926,12.533 28.467,6.074 20.5,6.074C12.533,6.074 6.074,12.533 6.074,20.5C6.074,28.467 12.533,34.926 20.5,34.926Z"
            fill={fill}
          />
        </g>
      </svg>
      {image ? (
        <LocationImage image={{ ...image, attribution: '' }} sizes="2vw" />
      ) : null}
    </Container>
  );
};

export default Marker;
