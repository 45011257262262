import Analytics, { AnalyticsInstance } from 'analytics';
import seedtraceAnalyticsPlugin from './seedtrace-analytics-plugin';
import googleAnalyticsPlugin from './google-analytics-plugin';

// here we define the analytics. we send events to our own server and to google if a tracking id is given.
// For both we have created our own plugin
const analytics = (gaTrackingId: string): AnalyticsInstance => {
  let plugins = [seedtraceAnalyticsPlugin()];
  if (gaTrackingId) {
    plugins = [seedtraceAnalyticsPlugin(), googleAnalyticsPlugin(gaTrackingId)];
  }

  return Analytics({
    app: 'seedtrace-static-site-generator',
    // @ts-ignore
    plugins: plugins,
    debug: process.env.NODE_ENV === 'development',
  });
};

// Set to global so analytics plugin will work with Gatsby
// if (typeof window !== 'undefined') {
//   window.Analytics = analytics;
// }

export default analytics;
