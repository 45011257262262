import { useEffect, useState } from 'react';

const useWindowOrientation = (): boolean => {
  const [portrait, setPortrait] = useState<boolean>(true);

  useEffect(() => {
    const handler = () => {
      const angle = screen.orientation?.angle || window.orientation;

      if (90 === angle || -90 === angle) {
        setPortrait(false);
      } else {
        setPortrait(true);
      }
    };

    handler();
    window.addEventListener('orientationchange', handler, false);

    () => window.removeEventListener('orientationchange', handler);
  }, []);

  return portrait;
};

export default useWindowOrientation;
