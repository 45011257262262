import React, { FC, ReactNode, useContext, useState } from 'react';

export enum StackedArea {
  PAGE = 'PAGE_AREA',
  CONTENT = 'CONTENT_AREA',
  SIDEBAR = 'SIDEBAR_AREA',
}

interface BreadCrumbListItem {
  title: string;
  icon?: FC;
  action?: () => void;
}

export interface StackableContent {
  id: string;
  area: StackedArea;
  renderComponent: (
    isActivePage?: boolean,
    otherProps?: Record<string, unknown>
  ) => ReactNode;
  isActive: boolean;
  breadcrumb: BreadCrumbListItem | null;
  layout: {
    hasBackButton: boolean;
    hasHeader: boolean;
    hasHeaderScrollEffect: boolean;
    hasFooter: boolean;
  } | null;
}

export interface StackedContentContextValues {
  activateStackedContent: (area: StackableContent) => void;
  deactivateStackedContent: (
    key: StackableContent['id'] | StackableContent['id'][],
    transitionDuration?: number
  ) => void;
  deactivateActiveContent: () => void;
  deactivateAllStackedContents: () => void;
  stackedContents: Array<StackableContent>;
  breadcrumbs: BreadCrumbListItem[];
  activeStackedContent: StackableContent | null;
}

interface Props {
  children: (activePageContextValues: StackedContentContextValues) => ReactNode;
}

const StackableContentContext =
  React.createContext<StackedContentContextValues>({
    activateStackedContent: () => {
      console.error('unimplemented context activateStackedContent');
    },
    deactivateStackedContent: () => {
      console.error('unimplemented context deactivatePage');
    },
    deactivateActiveContent: () => {
      console.error('unimplemented context deactivate active content');
    },
    deactivateAllStackedContents: () => {
      console.error('unimplemented context deactivate all content');
    },
    stackedContents: [],
    breadcrumbs: [],
    activeStackedContent: null,
  });

const StackedContentConsumer = StackableContentContext.Consumer;

const StackableContentProvider: React.FC<Props> = ({ children }: Props) => {
  const [stackableContents, setStackableContents] = useState<
    Array<StackableContent>
  >([]);

  const activeStackedContent =
    stackableContents[stackableContents.length - 1] || null;

  /**
   * Activates a page and renders it in it's designated AREA on desktop and overlaying all content on mobile.
   * For more info refer to the Readme
   * Can be used like so:
   * @example activateStackedContent({
      id: YOUR_ID,
      area: StackedArea.PAGE, // Or StackedArea.AREA
      breadcrumb: YOUR_BREADCRUMB_GOES_HERE,
      component: <YourComponent />,
      layout: {
        hasBackButton: true,
        hasHeader: true,
        hasHeaderScrollEffect: true,
      },
      isActive: true,
    });
   * @param key Can be `one key` or a `list of keys`
   * @returns
   */
  const activateStackedContent = (content: StackableContent) => {
    const isContentAlreadyActivated = stackableContents.some(
      (stackedContent) => stackedContent.id === content.id
    );

    // Prevents the content from being activated several times if a user double clicks or the activates mutiple times
    if (!isContentAlreadyActivated) {
      setStackableContents((cur) => [...cur, content]);
    } else {
      // eslint-disable-next-line no-console
      console.warn('Stackable content already exists', content.id);
    }
  };

  /**
   * Deactivate a page that is in the active pages list
   * @param key Can be `one key` or a `list of keys`
   * @returns
   */
  const deactivateStackedContent = (
    key: StackableContent['id'] | StackableContent['id'][]
  ) => {
    // can be one or multiple keys, so make sure we always make this a list of keys
    const keys = typeof key === 'string' ? [key] : key;
    setStackableContents((pages) =>
      pages.map((page) => {
        if (keys.includes(page.id)) {
          return { ...page, isActive: false };
        }

        return page;
      })
    );

    // Removes the page after the transition is done
    const cleanupTimeout = setTimeout(() => {
      cleanupStackableContents(keys);
    }, 500);

    return () => {
      clearTimeout(cleanupTimeout);
    };
  };

  // Deactivates the active stacked content ( last stackable content added to the stackableContent array)
  const deactivateActiveContent = () => {
    if (activeStackedContent) {
      deactivateStackedContent(activeStackedContent.id);
    }
  };

  // Deactivates all the stacked contents at once.
  const deactivateAllStackedContents = () => {
    const ids = stackableContents.map((content) => content.id);
    deactivateStackedContent(ids);
  };

  // Removes the stackable contents that were just removed in the deactivate function
  const cleanupStackableContents = (keys: StackableContent['id'][]) => {
    const filteredPages = stackableContents.filter(
      (page) => !keys.includes(page.id)
    );

    setStackableContents(filteredPages);
  };

  const stackedContentValues: StackedContentContextValues = {
    activateStackedContent,
    deactivateStackedContent,
    deactivateActiveContent,
    deactivateAllStackedContents,
    stackedContents: stackableContents,
    breadcrumbs: stackableContents
      .map((content) =>
        content.breadcrumb
          ? { ...content.breadcrumb, action: deactivateActiveContent }
          : null
      )
      .filter((breadcrumb) => breadcrumb !== null),
    activeStackedContent,
  };

  return (
    <StackableContentContext.Provider value={stackedContentValues}>
      {children(stackedContentValues)}
    </StackableContentContext.Provider>
  );
};

const useStackableContent = (): StackedContentContextValues => {
  return useContext(StackableContentContext);
};

/**
 * Returns the initial breadcrumbs for each stackable content. This way the breadcrumbs don't change when deactivating a page for example.
 */
export const useStackableBreadcrumbs = (): BreadCrumbListItem[] => {
  const { breadcrumbs } = useStackableContent();
  const [breadcrumbsToShow] = useState(breadcrumbs);

  return breadcrumbsToShow;
};

export {
  StackableContentProvider,
  StackedContentConsumer,
  useStackableContent,
};

export default StackableContentContext;
