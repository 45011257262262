import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const FooterContainer = styled.div<{ isMobile: boolean }>`
  width: 100%;
  margin-top: 60px;
  height: ${({ theme }) => theme.distances.footerHeight}px;
  text-align: center;
  color: ${({ theme }) => theme.palette.grey['A800']};
  background-color: ${({ theme }) => theme.backgrounds.light};
  font-weight: 300;
  padding: 24px;

  ${({ isMobile, theme }) =>
    !isMobile &&
    `
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    position: relative;
    height: auto;
    margin-left: ${theme.distances.desktopSidebarWidth}px;
    width: calc(100% - ${theme.distances.desktopSidebarWidth}px);
    padding-left: 30px;
    padding-right: 30px; 
    `}
`;

const SeedtraceLink = styled.a`
  text-decoration: none;
  font-size: 12px;
  font-weight: 300;
  display: block;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const LegalLinks = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ isMobile }) => (isMobile ? '4px' : 0)};
`;

const LegalLink = styled.a`
  text-decoration: none;
  font-size: 12px;
  font-weight: 300;
  color: inherit;
  align-items: center;
`;

const Logo = styled.img`
  display: inline;
  width: 70px;
  vertical-align: bottom;
  margin-bottom: 6px;
`;

const LinkContainer = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) =>
    !isMobile &&
    `
    display: flex;
    align-items: center;
    `}
`;

const DividerContainer = styled.div`
  margin: 0 6px;
`;

interface Props {
  className?: string;
  isMobile?: boolean;
}

const Footer: React.FC<Props> = ({ className, isMobile = false }: Props) => {
  const { t } = useTranslation();

  return (
    <FooterContainer className={className} isMobile={isMobile}>
      <span>
        Powered by{' '}
        <Logo src="/seedtrace_logo_wordmark_black.svg" alt="seedtrace logo" />
      </span>
      <LinkContainer isMobile={isMobile}>
        <SeedtraceLink href="https://seedtrace.org" target="_blank">
          seedtrace.org
        </SeedtraceLink>
        {!isMobile && <DividerContainer>|</DividerContainer>}
        <LegalLinks isMobile={isMobile}>
          <LegalLink href={t('link-imprint')} target="_blank">
            {t('imprint')}
          </LegalLink>
          <DividerContainer>|</DividerContainer>
          <LegalLink href={t('link-privacy-policy')} target="_blank">
            {t('data-privacy')}
          </LegalLink>
        </LegalLinks>
      </LinkContainer>
    </FooterContainer>
  );
};

export default Footer;
