import { useContext } from 'react';
import { ContentBlockDataContext } from 'components/Contexts';

const useContentBlockData = <T>(): { data: T } => {
  const { data } = useContext(ContentBlockDataContext);

  return { data };
};

export default useContentBlockData;
