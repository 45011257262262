import React, { ReactChild } from 'react';
import { MediaType } from 'types/types';
import { Background as BackgroundImage } from 'react-imgix';
import { createCrop } from 'utils/image';
import styled from 'styled-components';

const BackgroundColor = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

interface Props {
  media: MediaType;
  className?: string;
  children?: ReactChild;
}

const BackgroundMedia: React.FC<Props> = ({
  className = '',
  media,
  children,
}: Props) => {
  if (!media) {
    return <div className={className}>{children}</div>;
  }

  if (media.__typename === 'ImageType' && media.url) {
    const cropParams = createCrop(media.crop);

    return (
      <BackgroundImage
        className={className}
        src={media.url}
        imgixParams={cropParams.length ? { rect: cropParams } : {}}
      >
        {children}
      </BackgroundImage>
    );
  }

  if (media.__typename === 'ColorType') {
    return (
      <BackgroundColor className={className} backgroundColor={media.hexCode}>
        {children}
      </BackgroundColor>
    );
  }

  return <div className={className}>{children}</div>;
};

export default BackgroundMedia;
