import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BreadCrumbListItem } from 'utils/breadcrumbUtils';
import { useStackableContent } from './StackedContent/StackableContentContext';
import { BreadcrumbConfig } from 'types/types';
import { ProductJourneyPages } from './ProductJourney/pages';
import { VerifiedPaymentPages } from './VerifiedPayments/constants/pages';
import { breadcrumbUtils } from 'utils';

const Container = styled.div<{
  mobile: boolean;
}>`
  display: inline-flex;
  background: #fff;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  padding-left: 16px;

  svg {
    width: 19px;
    height: 17px;
    z-index: 2;
  }

  ${({ mobile }) =>
    mobile &&
    `
    padding-left: 10px;
  `}
`;

const Item = styled.div<{
  isRounded: boolean;
  isWhite: boolean;
}>`
  display: flex;
  position: relative;
  padding: 9px 20px;
  align-items: center;
  border-width: 0;
  border: none;
  box-shadow: none;
  outline: 0;
  min-height: 38px;

  ${({ isRounded, theme: { palette } }) => `
    background: ${isRounded ? palette.grey['A800'] : palette.grey[700]};
    color: #fff;
  `}

  ${({ isRounded }) =>
    isRounded &&
    `
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
  `}
  
  ${({ isRounded, theme: { palette } }) =>
    !isRounded &&
    `
    cursor: pointer;
    
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 19px 0 19px 10px;
      border-color: transparent transparent transparent ${palette.grey[700]};
      right: 0;
      transform: translateX(100%);
      top: 0;
      z-index: 2;
      outline: 0;
    }
    
    // hide the black line on some android phones
    &:before {
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      background: ${palette.grey[700]};
      right: 0;
      top: 0;
      z-index: 2;
    }
  `}

  ${({ isWhite, theme: { palette } }) =>
    isWhite &&
    `
    background: #fff;
    padding: 9px 12px 9px 0;
    color: ${palette.text.primary};

    &:after {
      border-color: transparent transparent transparent #fff;
    }

    &:before {
      background: #fff;
    }
  `}
`;

const Title = styled.div<{
  setMaxWidth: boolean;
}>`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 8px;
  margin-right: 8px;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${({ setMaxWidth }) =>
    setMaxWidth &&
    `
    max-width: 80px;
  `}
`;

const StyledIcon = styled(({ icon: Icon, ...props }) => <Icon {...props} />)`
  width: 20px;
  height: 20px;

  path {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
`;

interface Props {
  className?: string;
  config: BreadcrumbConfig[];
  mobile?: boolean;
  backToStartCrumb?: {
    type: ProductJourneyPages.PRODUCT_JOURNEY | VerifiedPaymentPages.OVERVIEW;
  }; // This displays the very first breadcrumb which returns the user all the way back to the first page
  showFullCrumbs?: boolean; // Shows the entire text of the breadcrumbs, by default these will be limited
}

const Breadcrumbs: React.FC<Props> = ({
  config,
  className,
  mobile,
  backToStartCrumb = undefined,
  showFullCrumbs = false,
}: Props) => {
  // We always show the last two available crumbs
  // The rest we don't show to stay within the screen.
  const breadcrumbsToShow = config.slice(-2);
  const { deactivateAllStackedContents } = useStackableContent();
  const { t } = useTranslation();
  const backToStartBreadcrumb: BreadCrumbListItem = backToStartCrumb
    ? {
        ...breadcrumbUtils.getBreadcrumb(backToStartCrumb.type, t),
        action: deactivateAllStackedContents,
      }
    : null;

  return (
    <Container className={className} mobile={mobile}>
      {backToStartBreadcrumb && (
        <Item
          onClick={backToStartBreadcrumb.action}
          isWhite={true}
          isRounded={false}
        >
          {backToStartBreadcrumb.icon ? (
            <StyledIcon icon={backToStartBreadcrumb.icon} />
          ) : null}
        </Item>
      )}

      {breadcrumbsToShow.map(({ Icon, icon, title, action }, index) => {
        // The item should be rounded if it's the only item in the breadcrumbs, OR it's the second item ( can only be maximum of 2)
        const isRounded = breadcrumbsToShow.length === 1 || index === 1;
        // The item should be white if it's the first breadcrumb, if the showBackToStartCrumb is available it should always be colored.
        const isWhite = backToStartCrumb ? false : index === 0;
        // The width should only be limited on the first crumb, if there are multiple crumbs.
        const limitTitleWidth = showFullCrumbs
          ? false
          : breadcrumbsToShow.length > 1 && index === 0;
        // It's the last crumb if the length of crumbs -1 is equal to the index
        // On this crumb no actions should be performed.
        const isLastCrumb = breadcrumbsToShow.length - 1 === index;

        return (
          <Item
            key={title}
            isRounded={isRounded}
            isWhite={isWhite}
            onClick={() => {
              if (!isLastCrumb) {
                action();
              }
            }}
          >
            {Icon ? Icon : icon ? <StyledIcon icon={icon} /> : null}

            <Title setMaxWidth={limitTitleWidth}>{title}</Title>
          </Item>
        );
      })}
    </Container>
  );
};

export default Breadcrumbs;
