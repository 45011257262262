import ReactGA from 'react-ga4';
import { AnalyticsPayloadType, AnalyticsPluginType } from '../types/types';

const analyticsPlugin = (meassurementId: string): AnalyticsPluginType => ({
  name: 'google-analytics-plugin',

  initialize: () => {
    ReactGA.initialize(meassurementId);
  },

  page: ({ payload }: { payload: AnalyticsPayloadType }) => {
    const {
      properties: { path },
    } = payload;

    ReactGA.send({ hitType: 'pageview', page: path, title: path });
  },

  track: ({ payload }: { payload: AnalyticsPayloadType }) => {
    const {
      event,
      properties: { contentType },
    } = payload;

    ReactGA.event({
      category: 'all',
      action: event,
      label: contentType,
    });
  },

  loaded: () => true,
});

export default analyticsPlugin;
