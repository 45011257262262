import React from 'react';
import errors from 'constants/errors';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import { ButtonOptions } from './NotFoundPage/types';

interface Props {
  error?: string;
  errorMessage?: string;
}

const BUTTON_LINKS: ButtonOptions = {
  primary: {
    text: 'Home page',
    path: 'https://seedtrace.org',
  },
  secondary: {
    text: 'Blog',
    path: 'https://seedtrace.org/blog',
  },
};

const ErrorPage: React.FC<Props> = ({ error, errorMessage }: Props) => {
  const { productNotFound, productNotPublished } = errors;
  const notFound: boolean =
    error === productNotFound || error === productNotPublished;

  if (notFound) {
    return (
      <NotFoundPage
        title="Product not found"
        description="There is nothing published here yet. But since you are already here, why not have a look around our website?"
        buttonOptions={BUTTON_LINKS}
        is404
      />
    );
  }

  if (errorMessage) {
    return (
      <NotFoundPage
        title="An error has occured"
        description={errorMessage}
        buttonOptions={BUTTON_LINKS}
      />
    );
  }

  return (
    <NotFoundPage
      title="Unknown Error"
      description={
        'We are so sorry, but we can not access this page. Since you are already here, why not have a look around?'
      }
      buttonOptions={BUTTON_LINKS}
    />
  );
};

export default ErrorPage;
