import {
  AboutCustomPreview,
  VerifiedPaymentCustomPreview,
} from 'components/Mobile/CustomPreview';
import Cart from 'images/icons/cart.svg';
import Check from 'images/icons/check.svg';
import Equal from 'images/icons/equal.svg';
import FeedbackBubble from 'images/icons/feedback.svg';
import Pin from 'images/icons/pin.svg';
import AboutIcon from 'images/icons/speach_bubble.svg';
import { FC } from 'react';
import { ContentTypeEnum } from 'types/types';

interface ContentBlockDetails {
  inversedHeader?: boolean;
  reducedHeaderHeight?: boolean;
  icon: FC;
  defaultOpen?: boolean;
  iconSmall?: boolean;
  hiddenOpen?: boolean;
  MobilePreviewContent?: FC<{ active: boolean }>;
  transparentBody?: boolean;
}

const contentBlocks: Partial<Record<ContentTypeEnum, ContentBlockDetails>> = {
  [ContentTypeEnum.ABOUT_BLOCK]: {
    inversedHeader: true,
    reducedHeaderHeight: true,
    icon: AboutIcon,
    defaultOpen: false,
    MobilePreviewContent: AboutCustomPreview,
  },
  [ContentTypeEnum.MORE_INFO_BLOCK]: {
    inversedHeader: true,
    reducedHeaderHeight: true,
    icon: AboutIcon,
    defaultOpen: false,
  },
  [ContentTypeEnum.VERIFIED_PAYMENT_BLOCK]: {
    inversedHeader: true,
    icon: Equal,
    hiddenOpen: true,
    defaultOpen: false,
    MobilePreviewContent: VerifiedPaymentCustomPreview,
  },
  [ContentTypeEnum.PRODUCT_JOURNEY_BLOCK]: {
    inversedHeader: true,
    icon: Pin,
    defaultOpen: false,
  },
  [ContentTypeEnum.IMPACT_FACTS_BLOCK]: {
    inversedHeader: true,
    reducedHeaderHeight: true,
    icon: Check,
    iconSmall: true,
    defaultOpen: false,
  },
  [ContentTypeEnum.PRODUCTS_BLOCK]: {
    transparentBody: true,
    icon: Cart,
    defaultOpen: true,
  },
  [ContentTypeEnum.FEEDBACK_BLOCK]: {
    icon: FeedbackBubble,
    iconSmall: true,
    defaultOpen: true,
  },
  [ContentTypeEnum.IMPACT_CLAIMS]: {
    icon: Check,
  },
};

export default contentBlocks;
