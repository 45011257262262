import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { InfoCircleFill } from '@styled-icons/bootstrap/InfoCircleFill';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px 4px 4px;
  background: ${({ theme }) => theme.palette.grey[50]};
  z-index: 1;
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background: ${({ theme }) => theme.palette.primary.main};
  }
`;

const TotalNumber = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  margin: 4px;
  padding-left: 8px;
  background: #fff;
  border-radius: ${({ theme }) => theme.borderRadius};
`;

const CurrencyNote = styled.div`
  display: flex;
  align-items: center;
  color: #7b7b7b;
  font-size: 11px;
  font-weight: 600;
  margin-right: 8px;
  line-height: 1.2;

  svg {
    margin: 14px;
  }
`;

const Number = styled.div`
  font-family: ${({ theme }) => theme.typography.header};
  font-size: 23px;
  line-height: 1.1;
  font-weight: 700;
`;

const SecondaryNumber = styled.div`
  font-family: ${({ theme }) => theme.typography.header};
  font-size: 10px;
  color: ${({ theme }) => theme.palette.grey['A500']};
  font-weight: 700;
`;

const Unit = styled.span`
  font-size: 20px;
`;

const Label = styled.div`
  width: 50%;
  font-family: ${({ theme }) => theme.typography.header};
  font-size: 12px;
  text-align: left;
`;

const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
`;

interface Value {
  value: number;
  unit?: string;
  unitBefore?: boolean;
}

interface Props {
  totals: {
    primaryNumber: Value;
    secondaryNumber?: Value;
    label: string;
    subLabel?: string;
    transform?: (number) => number | string;
  }[];
  className?: string;
}

const TotalContainer: React.FC<Props> = ({ totals, className }: Props) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      {totals.map(
        ({ primaryNumber, secondaryNumber, label, transform }, index) => {
          const unitFormatted = (
            <Unit>{`${
              primaryNumber.unit ? ` ${primaryNumber.unit} ` : ''
            }`}</Unit>
          );

          return (
            // eslint-disable-next-line react/no-array-index-key
            <TotalNumber key={index}>
              <NumberContainer>
                {!isNaN(primaryNumber.value) ? (
                  <Number>
                    {primaryNumber.unitBefore === true && unitFormatted}
                    {transform && primaryNumber.value
                      ? transform(primaryNumber.value)
                      : primaryNumber.value}
                    {primaryNumber.unitBefore !== true && unitFormatted}
                  </Number>
                ) : (
                  <Number>
                    <Skeleton width={80} />
                  </Number>
                )}
                {secondaryNumber?.value && (
                  <SecondaryNumber>
                    {transform && secondaryNumber.value
                      ? transform(secondaryNumber.value)
                      : secondaryNumber.value}{' '}
                    {secondaryNumber.unit}
                  </SecondaryNumber>
                )}
              </NumberContainer>

              <Label>{label}</Label>
            </TotalNumber>
          );
        }
      )}
      <CurrencyNote>
        <InfoCircleFill size={15} color="#AFAFAF" />
        {t('tracing.note-farmers-currency')}
      </CurrencyNote>
    </Container>
  );
};

export default TotalContainer;
