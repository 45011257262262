import React from 'react';
import styled from 'styled-components';
import { Breadcrumbs, BackLink } from 'components/Shared';
import { BreadcrumbConfig } from 'types/types';
import { ProductJourneyPages } from './ProductJourney/pages';
import { VerifiedPaymentPages } from './VerifiedPayments/constants/pages';

const headerContainerHeight = 130;
const headerContainerHeightMobile = 90;

const HeaderContainer = styled.div<{
  mobile?: boolean;
  transparent?: boolean;
  dynamicHeight?: boolean;
}>`
  position: relative;
  z-index: 1;
  padding: 30px 16px 10px 0;
  background: ${({ transparent }) => (transparent ? 'transparent' : '#fff')};
  ${({ dynamicHeight }) =>
    dynamicHeight && `height: ${headerContainerHeight}px;`}

  ${({ mobile, dynamicHeight }) =>
    mobile &&
    `
    ${dynamicHeight && `height: ${headerContainerHeightMobile}px;`}
    padding: 16px 16px 10px 0;
  `}
`;

interface Props {
  breadcrumbs: BreadcrumbConfig[];
  labelBackLink?: string;
  mobile: boolean;
  renderOnImpactClaims?: boolean;
  backToStartCrumb?: {
    type: ProductJourneyPages.PRODUCT_JOURNEY | VerifiedPaymentPages.OVERVIEW;
  }; // This displays the very first breadcrumb which returns the user all the way back to the first page
  dynamicHeight?: boolean;
  onPrev?: () => void;
}

const SlideHeader: React.FC<Props> = ({
  breadcrumbs,
  onPrev,
  mobile,
  labelBackLink,
  renderOnImpactClaims,
  dynamicHeight,
  backToStartCrumb,
}: Props) => {
  return (
    <HeaderContainer
      mobile={mobile}
      transparent={renderOnImpactClaims}
      dynamicHeight={dynamicHeight}
    >
      {!mobile && !!onPrev && (
        <BackLink
          label={labelBackLink}
          action={onPrev}
          color={renderOnImpactClaims && '#fff'}
        />
      )}
      <Breadcrumbs
        config={breadcrumbs}
        mobile={mobile}
        backToStartCrumb={backToStartCrumb}
      />
    </HeaderContainer>
  );
};

export default SlideHeader;
