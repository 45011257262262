import React, { Fragment } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import { MediaType } from 'types/types';
import { X as Close } from '@styled-icons/bootstrap/X';
import { media as gridMedia } from 'styled-bootstrap-grid';
import { ChevronRight } from '@styled-icons/bootstrap/ChevronRight';
import { ChevronLeft } from '@styled-icons/bootstrap/ChevronLeft';
import { useWindowOrientation } from 'hooks';
import Media from './Media';
import { ButtonPrimary } from './Button';

const Container = styled.div<{ portrait: boolean }>`
  .carousel .slide {
    background: transparent;

    iframe {
      margin: 0;
      width: 100%;
    }
  }

  .carousel .slider {
    overflow: visible !important;
  }

  .carousel .slider-wrapper {
    height: 100%;
  }

  .carousel .control-dots {
    position: fixed;
    bottom: 25px;
    z-index: 9;
  }

  .carousel .slider-wrapper.axis-horizontal .slider {
    height: 100%;
  }

  .carousel .control-dots .dot {
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.palette.grey[500]};
    box-shadow: none;
    opacity: 1;
  }

  .carousel .control-dots .dot.selected {
    background: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ portrait }) =>
    !portrait &&
    `
      .carousel-root {
        display: -webkit-box;
        width: 85%;
        height: 15rem;
        margin: auto;
      }
      
      .carousel .control-dots {
        position: fixed;
        bottom: -45px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 9;
      }
  `}
`;

const MediaItem = styled(Media)`
  width: 100%;
  height: 100%;

  .video-container {
    position: relative;
  }

  img {
    object-fit: contain;
    max-height: 20rem;

    ${gridMedia.lg`
      max-height: 30rem;
    `}
  }
`;

const StyledModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: fit-content;
  outline: none;
  border-radius: ${({ theme }) => theme.borderRadius};

  ${gridMedia.md`
    width: 80%;
  `}
  ${gridMedia.lg`
    width: 50rem;
  `}
`;

const CloseButton = styled(ButtonPrimary)`
  position: fixed;
  top: -30px;
  right: 0;
  color: #fff !important;
  background: rgba(0, 0, 0, 0.7) !important;
  z-index: 1;
  padding: 3px !important;
  border-radius: 50% !important;
  transition: opacity 0.125s ease;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  ${gridMedia.md`
    top: -12px;
    right: -12px;
  `}
`;

const MediaContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const NavigationButton = styled(ButtonPrimary)<{ direction: string }>`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  color: #fff !important;
  background: rgba(0, 0, 0, 0.7) !important;
  z-index: 1;
  padding: 8px !important;
  border-radius: 50% !important;
  transition: opacity 0.125s ease;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  ${({ direction }) => `${direction}: 3px;`}

  ${({ direction }) => gridMedia.lg`${direction}: -70px;`}
  
   ${gridMedia.lg`
    position: fixed;
    top: calc(50% - 25px);
  `}
`;

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99999999,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
};

interface Props {
  carouselItems: MediaType[];
  onClose: () => void;
  indexMediaList?: number;
}

const MediaDetailViewModal: React.FC<Props> = ({
  carouselItems,
  onClose,
  indexMediaList = 0,
}: Props) => {
  const portrait = useWindowOrientation();

  return (
    <StyledModal
      ariaHideApp={false}
      style={customStyles}
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="Media Detailed View"
    >
      <Container portrait={portrait}>
        <CloseButton onClick={onClose}>
          <Close size={20} />
        </CloseButton>
        <Carousel
          renderArrowNext={(clickHandler, hasNext) => (
            <Fragment>
              {hasNext && (
                <NavigationButton direction="right" onClick={clickHandler}>
                  <ChevronRight size={20} />
                </NavigationButton>
              )}
            </Fragment>
          )}
          renderArrowPrev={(clickHandler, hasPrev) => (
            <Fragment>
              {hasPrev && (
                <NavigationButton direction="left" onClick={clickHandler}>
                  <ChevronLeft size={20} />
                </NavigationButton>
              )}
            </Fragment>
          )}
          selectedItem={indexMediaList}
          showStatus={false}
        >
          {carouselItems.map((mediaItem, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <MediaContainer key={`media-detail-${index}`}>
                <MediaItem media={mediaItem} />
              </MediaContainer>
            );
          })}
        </Carousel>
      </Container>
    </StyledModal>
  );
};

export default MediaDetailViewModal;
