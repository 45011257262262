import React from 'react';
import styled from 'styled-components';
import { BoxArrowUpRight as Link } from '@styled-icons/bootstrap/BoxArrowUpRight';
import { clickedIds } from 'constants/analytics';
import { useTranslation } from 'react-i18next';
import { Cart } from '@styled-icons/bootstrap';

const Container = styled.div`
  display: flex;
`;

const Detail = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  font-weight: 300;
`;

const DetailText = styled.div`
  padding-left: 6px;
  white-space: nowrap;

  a {
    display: block;
    color: inherit;
    text-decoration: none;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

interface Props {
  website: string;
  webshopUrl?: string;
  className?: string;
}

const CompanyDetails: React.FC<Props> = ({
  website,
  webshopUrl,
  className,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <Detail>
        <Link size={10} />
        <DetailText>
          <a
            href={website}
            target="_blank"
            rel="noreferrer"
            data-tracking-id={clickedIds.productSummary}
          >
            {t('website')}
          </a>
        </DetailText>
      </Detail>
      {webshopUrl && (
        <Detail>
          <Cart size={10} />
          <DetailText>
            <a
              href={webshopUrl}
              target="_blank"
              rel="noreferrer"
              data-tracking-id={clickedIds.webshop}
            >
              {t('buy-online')}
            </a>
          </DetailText>
        </Detail>
      )}
    </Container>
  );
};

export default CompanyDetails;
