import styled from 'styled-components';

const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  box-shadow: none;
  border: none;
  padding: 8px;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

export default IconButton;
