import { useContext } from 'react';
import { PageDataContext } from 'components/Contexts';
import { PageDataType } from 'types/types';

const usePageData = (): PageDataType => {
  const { pageData } = useContext(PageDataContext);

  return pageData;
};

export default usePageData;
