import { gql } from 'graphql-request';

export const ANALYTICS_MUTATION = gql`
  mutation PageEventMutation($input: CreateUserAnalyticsInput!) {
    createUserAnalytics(input: $input) {
      message
    }
  }
`;

export const ANALYTICS_BEHAVIOR_MUTATION = gql`
  mutation CreateBehaviourAnalytics($input: CreateBehaviourAnalyticsInput!) {
    createBehaviourAnalytics(input: $input) {
      message
    }
  }
`;

export const FEEDBACK_MUTATION = gql`
  mutation FeedbackMutation(
    $productId: UUID!
    $visitorId: ID
    $feedback: String
    $rating: Int
    $privacyAccepted: Boolean
  ) {
    feedback(
      productId: $productId
      visitorId: $visitorId
      feedback: $feedback
      rating: $rating
      privacyAccepted: $privacyAccepted
    ) {
      status
    }
  }
`;
