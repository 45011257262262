import React from 'react';
import { Breadcrumbs } from 'components/Shared';
import contentBlockConfig from 'constants/contentBlockConfig';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ContentTypeEnum } from 'types/types';

const BreadcrumbsContainer = styled.div<{ isMobile: boolean }>`
  margin-left: ${({ isMobile }) => (isMobile ? -12 : 0)}px;
`;

const ColoredIcon = styled.div`
  display: flex;
  align-items: center;

  path {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
`;
interface Props {
  contentBlockName: ContentTypeEnum;
  isMobile?: boolean;
  customTitle?: string;
}

const ContentBlockSingleBreadcrumb: React.FC<Props> = ({
  contentBlockName,
  isMobile = false,
  customTitle,
}: Props) => {
  const Icon = contentBlockConfig[contentBlockName]?.icon;
  const theme = useTheme();
  const { t } = useTranslation();

  const breadcrumbs = [
    {
      title: customTitle || t(`content-blocks.${contentBlockName}`),
      Icon: Icon ? (
        <ColoredIcon color={theme.palette.primary.main}>
          <Icon />
        </ColoredIcon>
      ) : null,
    },
  ];

  return (
    <BreadcrumbsContainer isMobile={isMobile}>
      <Breadcrumbs config={breadcrumbs} mobile={isMobile} />
    </BreadcrumbsContainer>
  );
};

export default ContentBlockSingleBreadcrumb;
