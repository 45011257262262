import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

/**
 * Listen to incoming messages from the business app
 * That's when the consumer preview is shown inside an iframe
 */
const useListenerToBusinessApp = (
  onEventTriggered?: (eventName: string) => void
): { loading: boolean; setLoading: (newLoading: boolean) => void } => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  /**
   * The data was sent from the business app
   * This will trigger the full getServerSideProps again
   */
  const forceRefetchData = () => {
    setLoading(true);
    router.replace(router.asPath);
  };

  useEffect(() => {
    window.addEventListener('message', (event) => {
      // No check for origin URL, possible security risk!!

      if (onEventTriggered) onEventTriggered(event.data);

      if (event.data === 'refetch') {
        forceRefetchData();
      }
    });
  }, []);

  return { loading, setLoading };
};

export default useListenerToBusinessApp;
