import React, { Fragment, ReactNode } from 'react';
import { ImageType, ImageVariant } from 'types/types';
import Imgix from 'react-imgix';
import { createCrop } from 'utils/image';
import styled from 'styled-components';
import Skeleton from './Skeleton/Skeleton';

const StyledImage = styled(Imgix)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ImageContainer = styled.div<{
  attribution: string;
  attributionInside: boolean;
}>`
  position: relative;
  overflow: visible;
  z-index: 1;

  ${({ attribution, theme, attributionInside }) =>
    attribution &&
    `
    &:after {
      content: '${attribution}';
      left: ${attributionInside ? '0' : '4px'};
      padding: ${attributionInside ? '4px' : '0'};
      position: absolute;
      bottom: 0;
      font-size: 12px;
      color: ${
        attributionInside ? theme.palette.text.primary : theme.palette.grey[400]
      };
      transform: ${attributionInside ? 'none' : 'translateY(calc(100% + 4px))'};
      background: ${
        attributionInside ? 'rgba(255, 255, 255, 0.6)' : 'transparent'
      };
      line-height: 1;
    }
  `}

  img {
    border-radius: inherit;
  }
`;

const ImageSkeleton = styled(Skeleton)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  border-radius: 0 !important;
`;

const DarkGradientOverlay = styled.div`
  position: absolute;
  border-top-right-radius: ${({ theme }) => theme.borderRadius} !important;
  border-top-left-radius: ${({ theme }) => theme.borderRadius} !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: linear-gradient(
    125.26deg,
    rgba(0, 0, 0, 0.7) 34.32%,
    rgba(0, 0, 0, 0.07) 77.01%
  );
`;

const placeholder =
  'https://storage.googleapis.com/seedtrace-dev-36a0b.appspot.com/defaults/placeholder-image.png';

interface Props {
  lazyload?: boolean;
  sizes?: string;
  className?: string;
  attributionInside?: boolean;
  image: ImageType | ImageVariant;
  children?: ReactNode;
  onClick?: () => void;
  stepIndex?: number;
  displaySkeleton?: boolean;
  gradientOverlay?: boolean;
}

const Image: React.FC<Props> = ({
  image,
  className = '',
  lazyload = false,
  attributionInside = false,
  sizes = '100vw',
  onClick = () => {}, // eslint-disable-line
  children,
  stepIndex,
  displaySkeleton = false,
  gradientOverlay = false,
}: Props) => {
  const { url = '', crop, attribution = '' } = image || {};
  const cropParams = createCrop(crop);
  const attributeConfig =
    url && lazyload
      ? {
          src: 'data-src',
          srcSet: 'data-srcset',
          sizes: 'data-sizes',
        }
      : {};

  return (
    <Fragment>
      {gradientOverlay && (
        <DarkGradientOverlay className="image-gradient-overlay" />
      )}
      <ImageContainer
        key={url}
        onClick={onClick}
        className={className}
        attribution={attribution}
        attributionInside={attributionInside}
      >
        {children}
        <StyledImage
          className={`${url && lazyload ? ' lazyload' : ''}`}
          sizes={sizes}
          htmlAttributes={{
            'data-tracking-step-index': stepIndex,
          }}
          src={url || placeholder}
          imgixParams={cropParams.length ? { rect: cropParams } : {}}
          attributeConfig={attributeConfig}
        />
      </ImageContainer>
      {displaySkeleton && <ImageSkeleton />}
    </Fragment>
  );
};

export default Image;
