import React from 'react';
import { ImpactClaimsType } from 'types/types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Image } from 'components/Shared';
import { impactCategoryConfig } from 'constants/impactCategoryConfig';
import { convertHexToRGBA } from 'utils/color';

type VariantType = 'light' | 'dark';

const Text = styled.div`
  font-size: 12px;
`;

const ButtonContainer = styled.div<{ variant: VariantType }>`
  display: flex;
  align-items: center;
  border-radius: 45px;
  padding: 5px 16px 5px 8px;

  ${({ variant, theme }) => {
    if (variant === 'dark') {
      return `
      background:${convertHexToRGBA(theme.palette.grey['A800'], 0.8)};
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: #fff;
      `;
    }

    return `
    color: ${theme.palette.grey['A300']};
    background: ${convertHexToRGBA(theme.palette.grey['A800'], 0.1)}};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    `;
  }}
`;

const ClaimImageContainer = styled.div`
  display: inline-flex !important;
  flex-direction: row;
  margin-right: 8px;
  margin-left: 12px;

  .claim-logo {
    display: flex;

    img,
    svg {
      border-radius: 50%;
      border: 1px solid #c9c9c9;
      padding: 2px;
      background: #fff;
      width: 26px !important;
      height: 26px !important;
      object-fit: fill;
      max-width: unset !important;
      margin-left: -12px !important;
    }
  }
`;

interface Props {
  impactClaims?: ImpactClaimsType;
  handleClick: (e) => void;
  variant?: VariantType;
}

const ImpactClaimButton: React.FC<Props> = ({
  impactClaims,
  handleClick,
  variant = 'dark',
}: Props) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer variant={variant} onClick={handleClick}>
      <ClaimImageContainer>
        {/* 
          The icons of the impact claims are displayed here. 
          If there are more than three impact claims, only the first three logos are displayed.
        */}
        {impactClaims.edges
          .filter((_, index) => index < 4)
          .map(({ node: impactClaim }) => {
            const FallbackIcon =
              impactCategoryConfig[
                impactClaim.impactCatalogItem.category.type
              ]?.['icon'];

            const iconUrl: string | undefined =
              impactClaim.impactCatalogItem.iconUrl;

            if (iconUrl)
              return (
                <Image
                  className="claim-logo"
                  key={impactClaim.id}
                  image={{ url: impactClaim.impactCatalogItem.iconUrl }}
                />
              );
            return (
              <span className="claim-logo" key={impactClaim.id}>
                <FallbackIcon />
              </span>
            );
          })}
      </ClaimImageContainer>
      <Text>{t('impact-claims.title')}</Text>
    </ButtonContainer>
  );
};

export default ImpactClaimButton;
