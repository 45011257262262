import React, { useState } from 'react';
import styled from 'styled-components';
import { convertHexToRGBA } from 'utils/color';
import { clickedIds } from 'constants/analytics';
import { ImageType, ImageVariant } from 'types/types';
import Image from './Image';
import ExpandableContent from './ExpandableContent';

const ProductContainer = styled.div<{ isMobile: boolean }>`
  margin: 0 8px;
  padding: 16px;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.05);
  height: fit-content;
  background: linear-gradient(
    to bottom,
    ${({ theme }) => convertHexToRGBA(theme.palette.primary.main, 0.2)},
    rgba(0, 0, 0, 0) 200px
  );

  &:hover {
    background: ${({ theme, isMobile }) =>
      !isMobile && convertHexToRGBA(theme.palette.primary.main, 0.2)};
    cursor: pointer;
  }
`;

const Product = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  height: 100%;
`;

const ProductImage = styled(Image)`
  margin: 8px auto 16px;
  height: 110px;
  width: 110px;
  border-radius: 8px;
  display: flex;

  img {
    height: auto;
    max-height: 110px;
    width: auto;
    margin: auto;
    object-fit: contain;
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 8px;
`;

interface Props {
  isMobile?: boolean;
  title: string;
  image: ImageType | ImageVariant;
  description: string;
  link;
}

const ProductCarouselItem: React.FC<Props> = ({
  isMobile = false,
  title,
  image,
  description,
  link,
}: Props) => {
  const [textExpanded, setTextExpanded] = useState<boolean>(false);
  const [hoverProduct, setHoverProduct] = useState<boolean>(false);
  const openLink = (link: string) => {
    if (link) {
      window.open(link, '_blank');
    } else {
      setTextExpanded(!textExpanded);
    }
  };

  return (
    <ProductContainer
      isMobile={isMobile}
      data-tracking-id={clickedIds.product(title)}
      onClick={() => openLink(link)}
      onMouseEnter={() => !isMobile && setHoverProduct(true)}
      onMouseLeave={() => !isMobile && setHoverProduct(false)}
    >
      <Product>
        <div>
          <ProductImage image={image} />
          <Title>{title}</Title>
          <ExpandableContent
            expanded={textExpanded}
            onExpand={(e: React.MouseEvent<HTMLDivElement>) => {
              e?.preventDefault();
              e?.stopPropagation();
              setTextExpanded(!textExpanded);
            }}
            height={52}
            displayFade={!hoverProduct}
            text={description}
          />
        </div>
      </Product>
    </ProductContainer>
  );
};

export default ProductCarouselItem;
