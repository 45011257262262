import React, { useState, Fragment } from 'react';
import styled, { useTheme } from 'styled-components';
import StarRatings from 'react-star-ratings';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LoadingButton from 'components/Shared/LoadingButton';
import { usePageData, useAnalytics } from 'hooks';
import graphQLClient from 'utils/graphql-client';
import { FEEDBACK_MUTATION } from 'graphql/mutations';
import { FeedbackData } from 'types/types';
import { clickedIds } from 'constants/analytics';
import Bugsnag from '@bugsnag/js';

const Headline = styled.h2`
  width: 100%;
`;

const Subtitle = styled.p<{
  center: boolean;
}>`
  color: ${({ theme }) => theme.palette.grey[700]};
  margin-bottom: 16px;

  ${({ center }) =>
    center &&
    `
    margin: 16px auto;
    width: 80%;
  `}
`;

const Container = styled.div<{
  center: boolean;
  hasGreyBackground: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 8px;
  background: ${({ hasGreyBackground, theme }) =>
    hasGreyBackground ? theme.backgrounds.light : '#fff'};
  min-height: 430px;

  ${({ center }) =>
    center &&
    `
    align-items: center;
    text-align: center;
  `}
`;

const Input = styled.textarea<{ hasLightBackground: boolean }>`
  background: ${({ theme, hasLightBackground }) =>
    hasLightBackground ? '#fff' : theme.palette.grey[100]};
  border-color: transparent;
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;
  width: 100%;
  height: 120px;
  outline: none;
`;

const RatingBox = styled.div`
  text-align: center;

  .error-message {
    left: 50%;
    top: 90%;
    transform: translateX(-50%);
  }
`;

const Form = styled.form`
  width: 100%;
`;

const FormControl = styled.div<{
  type?: string;
}>`
  position: relative;
  margin-bottom: 16px;

  ${({ type }) =>
    type === 'checkbox' &&
    `
    display: flex;
    margin-bottom: 24px;

    input {
      margin-right: 8px;
    }
  `}
`;

const Notice = styled.p`
  font-size: 14px;
  margin-bottom: 0;
  text-align: left;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Button = styled((props) => <LoadingButton {...props} />)<{
  $center?: boolean;
}>`
  padding: 12px 16px;
  width: 100%;
  text-align: center;

  ${({ $center }) =>
    $center &&
    `
    width: 200px;
  `}
`;

const ErrorMessage = styled.p`
  position: absolute;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.error.main};
  margin-bottom: 0;
  bottom: 0;
  transform: translateY(85%);
`;

const Checkbox = styled.input`
  margin-top: 6px;
`;

interface Props {
  data: FeedbackData;
  center?: boolean;
  hasGreyBackground?: boolean;
}

const FeedbackForm: React.FC<Props> = ({
  data: { headline, subtitle, ratingEnabled },
  center,
  hasGreyBackground = false,
}: Props) => {
  const {
    productId,
    company: { name: companyName },
  } = usePageData();
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    errors,
    formState,
    control,
    setValue,
    getValues,
  } = useForm();
  const { t } = useTranslation();
  const { analytics } = useAnalytics();
  const onSubmit = async ({ rating = null, feedback, privacyAccepted }) => {
    const visitorId = analytics.getState().user.anonymousId;

    try {
      await graphQLClient.request(FEEDBACK_MUTATION, {
        productId,
        visitorId,
        rating,
        feedback,
        privacyAccepted,
      });

      setSuccess(true);
    } catch (error) {
      Bugsnag.notify(error, (event) => {
        event.addMetadata('data', {
          error,
          productId,
          feedback,
          rating,
          privacyAccepted,
          request: 'FEEDBACK_MUTATION',
          userFacing:
            'Generic error is shown to the user after submitting the feedback.',
          file: 'FeedbackForm.tsx',
          method: 'onSubmit',
        });
      });
      setError(true);
    }
  };
  const { palette } = useTheme();

  return (
    <Container hasGreyBackground={hasGreyBackground} center={center}>
      {success && (
        <Fragment>
          <Headline>{t('success-headline')}</Headline>
          <Subtitle center={center}>
            {t('success-subtitle', { company: companyName })}
          </Subtitle>
        </Fragment>
      )}
      {error && (
        <Fragment>
          <Headline>{t('error-headline')}</Headline>
          <Subtitle center={center}>{t('error-subtitle')}</Subtitle>
        </Fragment>
      )}
      {!success && !error && (
        <Fragment>
          <Headline>{headline}</Headline>
          <Subtitle center={center}>{subtitle}</Subtitle>
          <Form>
            {ratingEnabled && (
              <FormControl>
                <RatingBox>
                  <Controller
                    control={control}
                    name="rating"
                    rules={{ required: true }}
                    render={({ onChange, value }) => (
                      <StarRatings
                        rating={value}
                        starEmptyColor={palette.grey[300]}
                        starRatedColor={palette.primary.main}
                        starHoverColor={palette.primary.main}
                        changeRating={onChange}
                        numberOfStars={5}
                        name="rating"
                        starDimension="24px"
                        disabled={formState.isSubmitting}
                      />
                    )}
                  />
                  {errors.rating && (
                    <ErrorMessage className="error-message">
                      {t('required-error')}
                    </ErrorMessage>
                  )}
                </RatingBox>
              </FormControl>
            )}
            <FormControl>
              <Input
                name="feedback"
                ref={register({ required: true })}
                placeholder={t('feedback-placeholder')}
                disabled={formState.isSubmitting}
                hasLightBackground={hasGreyBackground}
              />
              {errors.feedback && (
                <ErrorMessage className="error-message">
                  {t('required-error')}
                </ErrorMessage>
              )}
            </FormControl>
            <FormControl type="checkbox">
              <Checkbox
                name="privacyAccepted"
                ref={register({ required: true })}
                type="checkbox"
                disabled={formState.isSubmitting}
              />
              <Notice
                onClick={(event) => {
                  const target = event.target as Element;
                  if (target.localName === 'a') return;
                  const { privacyAccepted } = getValues();
                  setValue('privacyAccepted', !privacyAccepted, {
                    shouldValidate: true,
                  });
                }}
                dangerouslySetInnerHTML={{ __html: t('privacy-message') }}
              />
              {errors.privacyAccepted && (
                <ErrorMessage>{t('privacy-error')}</ErrorMessage>
              )}
            </FormControl>
            <Button
              $center={center}
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
              data-tracking-id={clickedIds.feedback}
            >
              {t('submit')}
            </Button>
          </Form>
        </Fragment>
      )}
    </Container>
  );
};

export default FeedbackForm;
