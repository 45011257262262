import { ProductJourneyPages } from 'components/Shared/ProductJourney/pages';
import { VerifiedPaymentPages } from 'components/Shared/VerifiedPayments/constants/pages';
import contentBlockConfig from 'constants/contentBlockConfig';
import { TFunction } from 'i18next';
import { FC } from 'react';
import { ContentTypeEnum } from 'types/types';

export interface BreadCrumbListItem {
  key: string;
  title: string;
  icon?: FC;
  action?: () => void;
}

export const getBreadcrumb = (
  breadcrumbType: string,
  translate: TFunction<'translation', undefined>,
  customTitle?: string
): BreadCrumbListItem => {
  switch (breadcrumbType) {
    case ProductJourneyPages.PRODUCT_JOURNEY:
      return {
        key: ProductJourneyPages.PRODUCT_JOURNEY,
        title: customTitle
          ? customTitle
          : translate(
              `content-blocks.${ContentTypeEnum.PRODUCT_JOURNEY_BLOCK}`
            ),
        icon: contentBlockConfig[ContentTypeEnum.PRODUCT_JOURNEY_BLOCK].icon,
      };
    case ProductJourneyPages.IMPACT_CLAIM_LIST:
      return {
        key: ProductJourneyPages.IMPACT_CLAIM_LIST,
        title: customTitle ? customTitle : translate('impact-claims.title'),
      };
    case ProductJourneyPages.IMPACT_CLAIM:
      return {
        key: ProductJourneyPages.IMPACT_CLAIM,
        title: customTitle ? customTitle : translate('impact-claims.title'),
      };
    case ProductJourneyPages.DOCUMENTS:
      return {
        key: ProductJourneyPages.DOCUMENTS,
        title: customTitle ? customTitle : translate('impact-claims.documents'),
      };
    case ProductJourneyPages.SITE_CLUSTER_OVERVIEW:
      return {
        key: ProductJourneyPages.SITE_CLUSTER_OVERVIEW,
        title: customTitle ? customTitle : translate('origin'),
      };
    case ProductJourneyPages.BATCH_TRACING:
      return {
        key: ProductJourneyPages.BATCH_TRACING,
        title: customTitle ? customTitle : translate('batch-details'),
      };
    case ProductJourneyPages.SITE_BATCH_TRACING:
      return {
        key: ProductJourneyPages.SITE_BATCH_TRACING,
        title: customTitle ? customTitle : translate('batch-details'),
      };

    case VerifiedPaymentPages.OVERVIEW:
      return {
        key: VerifiedPaymentPages.OVERVIEW,
        title: customTitle
          ? customTitle
          : translate(
              `content-blocks.${ContentTypeEnum.VERIFIED_PAYMENT_BLOCK}`
            ),
        icon: contentBlockConfig[ContentTypeEnum.VERIFIED_PAYMENT_BLOCK].icon,
      };
    case VerifiedPaymentPages.COMMUNITIES:
      return {
        key: VerifiedPaymentPages.COMMUNITIES,
        title: customTitle ? customTitle : translate('tracing.communities'),
      };
    case VerifiedPaymentPages.TRANSACTIONS:
      return {
        key: VerifiedPaymentPages.TRANSACTIONS,
        title: customTitle ? customTitle : translate('tracing.transactions'),
      };

    default:
      break;
  }
};
