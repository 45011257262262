import React from 'react';
import { ProductType } from 'types/types';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import { media } from 'styled-bootstrap-grid';
import ProductCarouselItem from './ProductCarouselItem';

const responsive = {
  bigScreen: {
    breakpoint: { max: 3000, min: 1200 },
    items: 4,
    partialVisibilityGutter: 0,
  },
  desktop: {
    breakpoint: { max: 1200, min: 1024 },
    items: 4,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 140,
  },
};

const Container = styled.div<{
  center: boolean;
}>`
  ${({ center }) =>
    center &&
    `
    .react-multi-carousel-list {
      justify-content: center;
    }
  `};

  .react-multi-carousel-list {
    ${media.xs`
     overflow: visible;
  `}
  }

  .react-multi-carousel-track {
    padding: 16px 0;
  }

  .react-multi-carousel-item {
    min-width: 200px;

    ${media.xs`
    max-width: 250px;
  `}
  }

  .react-multiple-carousel__arrow {
    background: ${({ theme }) => theme.palette.primary.main};
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }

  .react-multiple-carousel__arrow--right {
    right: 0;
  }
`;

interface Props {
  products: ProductType[];
  isMobile?: boolean;
}

const ProductCarousel: React.FC<Props> = ({
  products = [],
  isMobile = false,
}: Props) => {
  return (
    <Container
      center={
        (!isMobile && products.length < 4) ||
        (isMobile && products.length === 1)
      }
    >
      <Carousel
        removeArrowOnDeviceType={['tablet', 'mobile']}
        responsive={responsive}
        ssr
        partialVisbile={isMobile && products.length > 1}
      >
        {products.map(
          ({ title, image, description, link, id }: ProductType) => {
            return (
              <ProductCarouselItem
                key={`product-${id}`}
                title={title}
                image={image}
                description={description}
                link={link}
                isMobile={isMobile}
              />
            );
          }
        )}
      </Carousel>
    </Container>
  );
};

export default ProductCarousel;
