import { useEffect } from 'react';

const useExactWindowHeight = (): void => {
  useEffect(() => {
    if (typeof window === undefined) return;

    const setViewPortHeightCSS = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', setViewPortHeightCSS);
    setViewPortHeightCSS();
  }, []);
};

export default useExactWindowHeight;
