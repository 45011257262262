import React, { useMemo, useEffect, useRef, ReactNode } from 'react';
import styled from 'styled-components';
import { HtmlField } from 'components/Shared';
import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown';
import { ChevronUp } from '@styled-icons/bootstrap/ChevronUp';
import { useTranslation } from 'react-i18next';

interface Props {
  children?: ReactNode | ReactNode[];
  text?: string;
  className?: string;
  expanded: boolean;
  onExpand: (e?: React.MouseEvent<HTMLDivElement>) => void;
  height?: number;
  displayFade?: boolean;
}

const Text = styled(HtmlField)<{
  expanded: boolean;
  height: number;
}>`
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  max-height: ${({ height }) => height}px;
  overflow: hidden;

  ${({ expanded }) =>
    expanded &&
    `
      max-height: 100%;
  `}
`;

const ExpandButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.grey[800]};
  width: 100%;
  margin-top: 14px;
`;

const ExpandMinimizeText = styled.span`
  font-size: 14px !important;
  margin-left: 16px;
`;

const IconTextContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    > path {
      stroke: ${({ theme }) => theme.palette.grey[500]};
      stroke-width: 2;
    }
  }
`;

const FadeOut = styled.div`
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 80%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 100%
  );
`;

const TextContainer = styled.div`
  position: relative;
`;

const ExpandableContent: React.FC<Props> = ({
  children,
  className = '',
  expanded,
  text,
  onExpand,
  height = 105,
  displayFade = true,
}) => {
  const { t } = useTranslation();
  const measureRef = useRef<null | HTMLDivElement>(null);
  const fullTextHeight =
    measureRef?.current?.querySelectorAll(':scope > div')?.[0]?.clientHeight ||
    0;

  const expandable = useMemo(() => {
    if (fullTextHeight !== 0) {
      return !(fullTextHeight < height);
    }
  }, [fullTextHeight, children]);

  useEffect(() => {
    if (!expandable && !expanded) {
      onExpand();
    }
    if (expandable && expanded) {
      onExpand();
    }
  }, [expandable]);

  return (
    <div className={className} onClick={expandable ? onExpand : undefined}>
      {text && (
        <TextContainer>
          <Text ref={measureRef} expanded={expanded} height={height}>
            {'<div>' + text + '</div>'}
          </Text>
          {expandable && !expanded && displayFade && <FadeOut />}
        </TextContainer>
      )}

      {children}

      {expandable && (
        <ExpandButton>
          {expanded ? (
            <IconTextContainer>
              <ChevronUp size={14} />
              <ExpandMinimizeText>{t('minimize-content')}</ExpandMinimizeText>
            </IconTextContainer>
          ) : (
            <IconTextContainer>
              <ChevronDown size={14} />
              <ExpandMinimizeText>{t('expand-content')}</ExpandMinimizeText>
            </IconTextContainer>
          )}
        </ExpandButton>
      )}
    </div>
  );
};

export default ExpandableContent;
