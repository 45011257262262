import React from 'react';
import styled from 'styled-components';

const Indicator = styled.div<{ active: boolean; width: number }>`
  height: 2px;
  background: #fff;
  display: table;
  width: ${({ width }) => width}px;
  border-radius: 4px;
  opacity: ${({ active }) => (active ? 1 : 0.5)};

  &:not(:last-child) {
    margin-right: 10px;
    width: calc(${({ width }) => width}px - 10px);
  }
`;

interface Props {
  active: boolean;
  width: number;
}

const SlideIndicator: React.FC<Props> = ({ active, width }: Props) => {
  return <Indicator active={active} width={width} />;
};

export default SlideIndicator;
