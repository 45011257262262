import React, { useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { Container, media } from 'styled-bootstrap-grid';
import { ButtonPrimary, ButtonSecondaryDark } from './Button';
import Switch from 'react-switch';
import { useTranslation } from 'react-i18next';
import Cookies from 'utils/Cookies';

const cookies = new Cookies();
const ACCEPTED_ALL = 'pp_cookie_consent_all';
const STATISTICS_CONSENT = 'pp_statistics_consent';

const StyledContainer = styled(Container)`
  display: flex;

  ${media.xs`
    flex-direction: column;
    width: unset;
  `}
`;

const Content = styled.div`
  width: 75%;

  ${media.xs`
    width: 100%;
  `}
`;

const Headline = styled.h6`
  font-size: 14px;
  margin-bottom: 8px;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-size: 12px;
  margin-bottom: 8px;

  a {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: none !important;
  }
`;

const Switches = styled.div`
  display: flex;

  ${media.xs`
    flex-direction: column;
  `}
`;

const Banner = styled.div<{
  positionAbsolute: boolean;
}>`
  position: ${({ positionAbsolute }) =>
    positionAbsolute ? 'absolute' : 'fixed'};

  box-shadow: 0 2px 10px 6px rgba(0, 0, 0, 0.2);
  width: 100%;
  bottom: 0;
  left: 0;
  background: #fff;
  padding: 16px;
  z-index: 9999999;

  ${({ positionAbsolute }) =>
    positionAbsolute &&
    `
    right: 0;
    width: unset;
  `}

  ${media.xs`
    padding: 16px 0;
  `}
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  margin-right: 16px;
`;

const FormControl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;

  &:first-of-type {
    margin-left: 0;
  }

  ${media.xs`
    margin-left: 0;
    margin-top: 8px;
  `}
`;

const Actions = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  ${media.xs`
    margin-top: 16px;
    justify-content: space-between;
  `}
`;

interface Props {
  onGranted: () => void;
  preview: boolean;
  positionAbsolute?: boolean;
}

const CookieBanner: React.FC<Props> = ({
  onGranted,
  preview,
  positionAbsolute = false,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState<{
    id?: boolean;
  }>({});

  const onStatisticsChanged = (granted: boolean): void => {
    if (granted) {
      onGranted();
    }
  };

  const config = [
    {
      id: 'necessary',
      disabled: true,
      label: t('necessary-cookies-label'),
    },
    {
      id: 'statistics',
      label: t('statistics-cookies-label'),
      cookie: STATISTICS_CONSENT,
      onAccepted: () => onStatisticsChanged(true),
    },
  ];

  const onAccepted = (acceptAll: boolean): void => {
    Object.keys(formState).forEach((id) => {
      const { disabled, cookie, onAccepted } = config.find((c) => c.id === id);
      const checked = acceptAll ? true : formState[id];

      if (disabled) return;

      cookies.set(cookie, checked, true);

      if (checked) onAccepted();
    });

    cookies.set(ACCEPTED_ALL, acceptAll, true);
    setOpen(false);
  };

  useEffect(() => {
    const cookieState = config.reduce((state, item) => {
      const accepted = cookies.get(item.cookie, true);
      if (accepted) item.onAccepted?.();

      return {
        ...state,
        [item.id]: item.disabled || accepted,
      };
    }, {});
    const open = !cookies.get(ACCEPTED_ALL, true);

    setFormState(cookieState);
    setOpen(open);
  }, []); // eslint-disable-line

  return (
    open &&
    !preview && (
      <Banner positionAbsolute={positionAbsolute}>
        <StyledContainer>
          <Content>
            <Headline>{t('headline-cookies')}</Headline>
            <Text>
              {t('text-cookies')}
              <a
                href={t('link-privacy-policy')}
                target={'_blank'}
                rel="noreferrer"
              >
                {t('data-privacy')}
              </a>
            </Text>
            <Switches>
              {config.map(({ disabled, label, id }) => {
                return (
                  <FormControl key={id}>
                    <Label>{label}</Label>
                    <Switch
                      onColor={theme.palette.primary.main}
                      height={18}
                      width={36}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      checked={formState[id]}
                      disabled={disabled}
                      onChange={(checked) =>
                        setFormState({
                          ...formState,
                          [id]: checked,
                        })
                      }
                    />
                  </FormControl>
                );
              })}
            </Switches>
          </Content>
          <Actions>
            <ButtonSecondaryDark onClick={() => onAccepted(false)}>
              {t('save-cookies-selection')}
            </ButtonSecondaryDark>
            <ButtonPrimary
              data-cy="accept-cookies"
              onClick={() => onAccepted(true)}
            >
              {t('accept-cookies-label')}
            </ButtonPrimary>
          </Actions>
        </StyledContainer>
      </Banner>
    )
  );
};

export default CookieBanner;
