import React, { ReactElement } from 'react';
import { ButtonPrimary as Button } from './Button';
import Loader from './Loader';
import styled from 'styled-components';
import { clickedIds } from 'constants/analytics';

const StyledButton = styled(Button)`
  position: relative;
  overflow: hidden;
`;

const ProgressContainer = styled.div<{
  visible: boolean;
}>`
  position: 'absolute';
  margin-left: 'auto';
  margin-right: 'auto';
  left: 0;
  right: 0;
  opacity: 0;
  transform: translateY(30px);
  transition: transform 0.125s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    color: ${({ theme }) => theme.palette.buttonText.primary};
  }

  ${({ visible }) =>
    visible &&
    `
    opacity: 1;
    transform: translate(0);
  `}
`;

const Label = styled.span<{
  visible: boolean;
}>`
  ${({ visible }) => {
    let styles = {
      transition: 'all 0.125s ease-in',
      opacity: 1,
      transform: 'translateY(0)',
    };

    if (!visible) {
      styles = {
        ...styles,
        opacity: 0,
        transform: 'translateY(-30px)',
      };
    }

    return styles;
  }}
`;

interface Props {
  children: ReactElement;
  loading: boolean;
  disabled: boolean;
  type: string;
  onClick: () => void;
}

const LoadingButton: React.FC<Props> = ({
  children,
  loading,
  disabled,
  onClick,
  type,
  ...rest
}: Props) => {
  return (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
      {...rest}
    >
      <ProgressContainer visible={loading}>
        <Loader />
      </ProgressContainer>
      <Label visible={!loading} data-tracking-id={clickedIds.feedback}>
        {children}
      </Label>
    </StyledButton>
  );
};

export default LoadingButton;
