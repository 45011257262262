import styled from 'styled-components';

const TransitioningPageWrapper = styled.div<{ isIntegration: boolean }>`
  min-height: 100vh;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
  min-height: 100dvh;
  width: 100%;
  max-height: 100vh;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: fill-available;
  max-height: 100dvh;
  overflow: hidden;
  position: relative;

  ${({ isIntegration }) =>
    isIntegration &&
    `
    height: auto;
    width: 100%;
    min-height: 100%;
    overflow: initial;
    position: relative;
`}
`;

export default TransitioningPageWrapper;
