import React from 'react';
import styled from 'styled-components';
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort';
import { ButtonOptions } from './types';
import Image from 'next/image';

const NotFoundContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Lato, sans-serif;
  color: #0c193b;
`;

const ImageWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

const ActionContainer = styled('div')`
  background: ${({ theme }) => theme.palette.grey[50]};
  display: flex;
  justify-content: center;
  padding: 50px;
  height: 50vh;
`;

const Content = styled('div')`
  max-width: 630px;
`;

const ButtonWrapper = styled('div')`
  padding-top: 25px;
  display: flex;
  align-items: center;
`;

const Headline = styled.h6`
  font-size: 22px;
  margin-bottom: 20px;
`;

const StyledImage = styled('div')`
  padding: 50px;
  width: 620px;
  height: 280px;
  position: relative;

  @media screen and (max-width: 680px) {
    width: 300px;
    height: 130px;
  }
`;

const Logo = styled('div')`
  position: absolute;
  top: 24px;
  left: 24px;
`;

const StyledDot = styled('div')`
  background: #ffcc47;
  border-radius: 100%;
  width: 15px;
  height: 15px;
`;

const LinkIconWrapper = styled('div')`
  position: relative;
  margin-right: 10px;
`;

const LinkIcon = styled(ArrowRightShort)`
  position: absolute;
  left: -15px;
  top: -7px;
  transition: all 0.3s ease-in-out;
`;

const StyledPrimaryButton = styled.button`
  background-color: #ffb801;
  color: #0c193b;
  padding: 10px 35px;
  border: none;
  border-radius: 5px;
  font-weight: 700;
  font-size: 12px;

  &:hover {
    background: #ffcc47;
    cursor: pointer;
  }
`;

const StyledLinkButton = styled.a`
  margin-left: 30px;
  position: relative;
  font-weight: 700;
  color: #0c193b;
  font-size: 12px;
  display: flex;

  &:hover {
    cursor: pointer;

    .link-icon {
      left: -5px;
    }
  }
`;

interface Props {
  title: string;
  description: string;
  is404?: boolean;
  buttonOptions: ButtonOptions;
}

const NotFoundPage: React.FC<Props> = ({
  title,
  description,
  is404 = false,
  buttonOptions,
}) => {
  return (
    <NotFoundContainer>
      <Logo>
        <Image
          alt="seedtrace logo"
          src="/seedtrace-logo-dark.png"
          width={120}
          height={20}
          objectFit="contain"
        />
      </Logo>
      <ImageWrapper>
        <StyledImage>
          <Image
            alt="page logo"
            layout="fill"
            src={is404 ? '/page-not-found.png' : '/transport-band.png'}
          />
        </StyledImage>
      </ImageWrapper>

      <ActionContainer>
        <Content>
          <div>
            <Headline>{title}</Headline>
            <p>{description}</p>
          </div>

          <ButtonWrapper>
            <StyledPrimaryButton
              onClick={() => {
                window.location.replace(buttonOptions.primary.path);
              }}
            >
              {buttonOptions.primary.text}
            </StyledPrimaryButton>

            <StyledLinkButton
              onClick={() => {
                window.location.replace(buttonOptions.secondary.path);
              }}
            >
              <LinkIconWrapper>
                <StyledDot />
                <LinkIcon size={30} className="link-icon" />
              </LinkIconWrapper>
              {buttonOptions.secondary.text}
            </StyledLinkButton>
          </ButtonWrapper>
        </Content>
      </ActionContainer>
    </NotFoundContainer>
  );
};

export default NotFoundPage;
