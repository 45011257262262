import React from 'react';
import styled from 'styled-components';
import { SdgType } from 'types/types';

const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Pill = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 8px;
  background: #fff;
  box-shadow: ${({ theme }) => theme.shadows[0]};
`;

const Text = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 4px 8px;
  font-weight: 600;
  background: #fff;
`;

const IconBox = styled.div<{
  background: string;
}>`
  height: 100%;
  background: ${({ background }) => background};
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;

  svg {
    height: auto;
    width: 18px;
  }

  svg path {
    fill: #fff;
  }
`;

const Logo = styled.img`
  height: 16px;
  width: 16px;
`;

interface Props {
  className?: string;
  sdgs: SdgType[];
}

const SdgPills: React.FC<Props> = ({ sdgs = [], className }: Props) => {
  return (
    <PillContainer className={className}>
      {sdgs.map(({ icon: Icon, color, title, iconUrl, number }) => {
        return (
          <Pill key={`sdg-pill-${number}`}>
            <IconBox background={color}>
              {/*// @ts-ignore*/}
              {Icon ? <Icon /> : <Logo src={iconUrl} />}
            </IconBox>
            <Text>{title}</Text>
          </Pill>
        );
      })}
    </PillContainer>
  );
};

export default SdgPills;
