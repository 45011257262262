export enum VerifiedPaymentPages {
  OVERVIEW = 'overview',
  COMMUNITIES = 'communities',
  TRANSACTIONS = 'transactions',
}

export const getVerifiedPaymentPageIndex = (
  name: VerifiedPaymentPages
): number => {
  return {
    [VerifiedPaymentPages.OVERVIEW]: 0,
    [VerifiedPaymentPages.COMMUNITIES]: 1,
    [VerifiedPaymentPages.TRANSACTIONS]: 2,
  }[name || VerifiedPaymentPages.OVERVIEW];
};
