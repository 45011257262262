import React from 'react';
import { Check } from '@styled-icons/bootstrap/Check';
import styled from 'styled-components';

const List = styled.ul`
  padding: 0;
  margin: 0;
`;

const Item = styled.ul`
  display: flex;
  grid-gap: 15px;
  margin: 0;
  padding: 13px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey['200']};
  font-size: 14px;
`;

interface Props {
  items: string[];
}

const CheckList: React.FC<Props> = ({ items }: Props) => {
  return (
    <List>
      {items.map((text) => (
        <Item key={text}>
          <div>
            <Check size={25} />
          </div>
          <span>{text}</span>
        </Item>
      ))}
    </List>
  );
};

export default CheckList;
