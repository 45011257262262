import { gql } from 'graphql-request';

export const BATCH_DETAILS_FRAGMENT = gql`
  fragment batchDetailsValues on BatchDetails {
    totalHarvestAmount
    amountOfFarms
  }
`;

export const SITE_GENERIC_BATCH_DETAILS_FRAGMENT = gql`
  fragment siteGenericBatchDetailsValues on SiteGenericBatchDetails {
    totalInboundWeight
    dateReceived
    totalOutboundWeight
    dateDispatched
    dateOfActivity
    siteActivityTitle
    rawMaterialId
  }
`;
export const SITE_FARM_BATCH_DETAILS_FRAGMENT = gql`
  fragment siteFarmBatchDetailsValues on SiteFarmBatchDetails {
    totalHarvestAmount
    amountOfFarms
    harvestPeriod {
      start
      end
    }
    dateOfPickup
    harvestYield
    harvestPickupMode
  }
`;

export const MIN_FARM_FRAGMENT = gql`
  fragment minFarmValues on Site {
    ... on Farm {
      id
      siteType
      batchDetails {
        ...siteFarmBatchDetailsValues
      }
    }
    ... on SiteCluster {
      id
      siteType
      sites {
        edges {
          node {
            ... on Farm {
              id
              siteType
              batchDetails {
                ...siteFarmBatchDetailsValues
              }
            }
          }
        }
      }
    }
  }
  ${SITE_FARM_BATCH_DETAILS_FRAGMENT}
`;

export const PRODUCT_BATCH_FRAGMENT = gql`
  fragment productBatchValues on ProductBatchData {
    rawMaterials {
      id
      batchDetails {
        ...batchDetailsValues
      }
      chainStepActivities {
        id
        incomingLinks {
          chainStepActivitySourceId
          chainStepActivityTargetId
          transportationMode
        }
        batchDetails {
          ...batchDetailsValues
        }
        siteBatchDetails {
          ...siteGenericBatchDetailsValues
        }
        activity {
          id
          site {
            ...minFarmValues
          }
        }
      }
    }
  }
  ${BATCH_DETAILS_FRAGMENT}
  ${SITE_GENERIC_BATCH_DETAILS_FRAGMENT}
  ${MIN_FARM_FRAGMENT}
`;
