import { useAnalytics } from 'hooks';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ImpactClaimsType } from 'types/types';

interface Props extends PropsWithChildren {
  impactClaims: ImpactClaimsType;
}

const ImpactClaimsContext = createContext(null);
const ImpactClaimsConsumer = ImpactClaimsContext.Consumer;

const ImpactClaimsProvider: FC<Props> = ({ children, impactClaims }) => {
  const [selectedClaimId, setSelectedClaimId] = useState<string>();
  const { trackActiveClaim } = useAnalytics();

  useEffect(() => {
    trackActiveClaim({
      impactClaimId: selectedClaimId,
    });
  }, [selectedClaimId]);

  const selectedClaim = useMemo(
    () =>
      impactClaims?.edges.find((claim) => claim.node.id === selectedClaimId)
        ?.node,
    [selectedClaimId, impactClaims]
  );

  const toggleImpactClaim = (claimId) => {
    if (selectedClaimId === claimId) {
      // This claim is currently selected, unselect
      setSelectedClaimId(undefined);
    } else {
      // Set as selected
      setSelectedClaimId(claimId);
    }
  };

  const handleCloseClaim = () => {
    setSelectedClaimId(undefined);
  };

  return (
    <ImpactClaimsContext.Provider
      value={{
        selectedClaim,
        selectedClaimId,
        toggleImpactClaim,
        impactClaims,
        handleCloseClaim,
        setSelectedClaimId,
      }}
    >
      {children}
    </ImpactClaimsContext.Provider>
  );
};

export { ImpactClaimsConsumer, ImpactClaimsProvider };

export default ImpactClaimsContext;
