import React, { FC, PropsWithChildren, createContext } from 'react';
import { PageDataType } from 'types/types';
import { ImpactClaimsProvider } from './ImpactClaimsProvider';
interface IPageDataContextValues {
  pageData?: PageDataType;
}

const PageDataContext = createContext<IPageDataContextValues>({
  pageData: undefined,
});

const PageDataConsumer = PageDataContext.Consumer;

interface Props extends PropsWithChildren {
  pageData: PageDataType;
}

const PageDataProvider: FC<Props> = ({ children, pageData }) => {
  const state = {
    pageData,
  };

  return (
    <PageDataContext.Provider value={state}>
      <ImpactClaimsProvider impactClaims={pageData.impactClaims}>
        {children}
      </ImpactClaimsProvider>
    </PageDataContext.Provider>
  );
};

export { PageDataConsumer, PageDataProvider };

export default PageDataContext;
