import React, { ReactChild, useEffect, useState } from 'react';
import { useActiveItem, useTracking } from 'hooks';
import useInterval from 'react-use/lib/useInterval';
import { pingInterval, eventType, componentIds } from 'constants/analytics';
import { AnalyticsInstance } from 'analytics';
import { TrackStepsArgs } from 'components/Shared/ProductJourney/types';
import { AnalyticsFake } from 'types/types';

interface TrackActiveClaimArgs {
  impactClaimId?: string;
}

export interface AnalyticsContextReturn {
  analytics: AnalyticsInstance | AnalyticsFake;
  trackSteps: (args: TrackStepsArgs) => void;
  trackActiveClaim: (args: TrackActiveClaimArgs) => void;
}

const AnalyticsContext = React.createContext<AnalyticsContextReturn>({
  analytics: {
    identify: () => ({}),
    page: () => ({}),
    track: () => ({}),
    user: () => ({}),
    getState: () => ({}),
  },
  trackSteps: () => console.info('context not initialized'),
  trackActiveClaim: () => console.info('context not initialized'),
});
const AnalyticsConsumer = AnalyticsContext.Consumer;

interface Props {
  children: ReactChild;
  productId: string;
  trackingAllowed: boolean;
  preview?: boolean;
  byQr?: string;
  gaTrackingId?: string;
}

const AnalyticsProvider: React.FC<Props> = ({
  children,
  productId,
  trackingAllowed,
  preview = false,
  byQr = '',
  gaTrackingId,
}: Props) => {
  // we use tracking hook to share tracking functionality between product page and integrations
  const { analytics, sessionActive, sessionId, trackSteps, visitorId } =
    useTracking({
      productId,
      trackingAllowed,
      preview,
      byQr,
      gaTrackingId,
    });
  const { activeItem } = useActiveItem();
  const interval = sessionActive ? pingInterval : null;
  const [activeClaimId, setActiveClaimId] = useState<string | undefined>(
    undefined
  );

  const trackActiveClaim = ({ impactClaimId }: TrackActiveClaimArgs) => {
    setActiveClaimId(impactClaimId);
  };

  // only initialize analytics if tracking is allowed
  useEffect(() => {
    if (!trackingAllowed) return;

    analytics.identify('', {
      productId,
    });
  }, [trackingAllowed]);

  // clickTracking is used for the click events
  const clickTracking = ({ target }) => {
    const { trackingId } = target.dataset;

    if (!trackingId || !trackingAllowed) return;

    analytics.track(eventType.click, {
      visitorId,
      productId,
      sessionId,
      target: trackingId,
    });
  };

  // via the pin tracking we can see how long a user has looked at a certain content block
  const pingTracking = () => {
    if (!trackingAllowed) return;

    analytics.track(
      eventType.ping,
      {
        visitorId,
        productId,
        sessionId,
        contentType: activeItem || componentIds.mainScreen,
        impactClaimId: activeClaimId,
        interval,
      },
      {
        plugins: {
          all: false,
          'seedtrace-analytics-plugin': true,
        },
      }
    );
  };

  useInterval(pingTracking, sessionActive ? pingInterval : null);

  useEffect(() => {
    window.addEventListener('click', (event) => {
      if (event) {
        clickTracking({ target: event.target });
      }
    });
  }, []);

  return (
    <AnalyticsContext.Provider
      value={{ analytics, trackSteps, trackActiveClaim }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export { AnalyticsProvider, AnalyticsConsumer };

export default AnalyticsContext;
