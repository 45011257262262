import React, { useRef } from 'react';
import { useIntersection } from 'react-use';

interface ReturnProps {
  intersectionRef: React.MutableRefObject<undefined>;
  isIntersecting: boolean;
}

const useIsIntersecting = (): ReturnProps => {
  const intersectionRef = useRef();
  const { isIntersecting } =
    useIntersection(intersectionRef, {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    }) || {};

  return {
    intersectionRef,
    isIntersecting,
  };
};

export default useIsIntersecting;
