import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '.';

const Container = styled.div<{
  includeMargin: boolean;
}>`
  display: flex;
  align-items: center;

  ${({ includeMargin }) =>
    includeMargin &&
    `
    margin-bottom: 15px;
  `}
`;

const Text = styled.h2`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.1em;
  text-align: left;
  margin: 5px 10px 0 0;
`;

interface Props {
  children: string;
  tooltip?: string;
  includeMargin?: boolean;
}

const SubTitle: React.FC<Props> = ({
  children,
  tooltip,
  includeMargin = true,
}: Props) => (
  <Container includeMargin={includeMargin}>
    <Text>{children}</Text>
    {tooltip && <Tooltip tooltipText={tooltip} />}
  </Container>
);

export default SubTitle;
