import { gql } from 'graphql-request';

export const MEDIA_LIST_TYPE_FRAGMENT = gql`
  fragment mediaListType on MediaType {
    ... on ColorType {
      __typename
      hexCode
    }
    ... on Video {
      __typename
      ...video
    }
    ... on ImageVariant {
      ...imageVariant
    }
  }
`;

export const VIDEO_FRAGMENT = gql`
  fragment video on Video {
    videoId: id
    fileName
    fileSize
    uri
    vimeoLink
    createdTimestamp
    thumbnail
    vimeoId
    transcodeStatus
  }
`;

export const IMAGE_TYPE_FRAGMENT = gql`
  fragment imageType on ImageType {
    __typename
    url
    crop {
      x
      y
      width
      height
    }
  }
`;

export const IMAGE_VARIANT_FRAGMENT = gql`
  fragment imageVariant on ImageVariant {
    __typename
    id
    imageId
    path
    url
    width
    height
    size
    crop {
      x
      y
      width
      height
    }
  }
`;

export const EXTENDED_IMAGE_TYPE_FRAGMENT = gql`
  fragment extendedImageType on ImageType {
    __typename
    attribution
    url
    crop {
      x
      y
      width
      height
    }
  }
`;
