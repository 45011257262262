import { useContext } from 'react';
import { ActiveItemContext } from 'components/Contexts';
import { ActiveItemUpdate, ContentTypeEnum } from 'types/types';

const useActiveItem = (): {
  activeItem: ContentTypeEnum;
  setActiveItem: (item: ContentTypeEnum) => void;
  openContentBlocks: ContentTypeEnum[];
  setOpenContentBlocks: (blocks: ContentTypeEnum[]) => void;
  accordionOpen: boolean;
  setAccordionOpen: (open: boolean) => void;
  preventAnimation: boolean;
  updateItemStatus: (update: ActiveItemUpdate) => void;
} => {
  return useContext(ActiveItemContext);
};

export default useActiveItem;
