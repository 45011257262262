import { Cookies as ReactCookies } from 'react-cookie';

const getExpirationDate = (date = null, expiringDays = 365) => {
  const expireDate = date ? new Date(date) : new Date();
  expireDate.setTime(expireDate.getTime() + expiringDays * 24 * 60 * 60 * 1000);
  return expireDate;
};

type valueType = number | string | boolean;

export default class Cookies {
  cookies: ReactCookies;

  constructor() {
    this.cookies = new ReactCookies();
  }

  get(cookie: string, isBool: boolean): valueType {
    if (isBool) {
      return this.cookies.get(cookie) === '1';
    }

    return this.cookies.get(cookie);
  }

  set(
    cookie: string,
    value: valueType,
    isBool: boolean,
    cookieExpiration?: Date
  ): void {
    if (isBool) {
      value = value === true ? 1 : 0;
    }

    this.cookies.set(cookie, value, {
      expires: cookieExpiration || getExpirationDate(),
    });
  }

  remove(cookie: string): void {
    return this.cookies.remove(cookie);
  }
}
