import {
  CommunityType,
  QueryResultType,
} from 'components/Shared/VerifiedPayments/types';
import { TRACING_QUERY } from 'graphql/queries';
import { useQuery } from 'hooks';
import React, {
  createContext,
  Dispatch,
  ReactChild,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { VerifiedPaymentsData } from 'types/types';

export interface ReturnProps {
  contentBlockData: VerifiedPaymentsData;
  query: QueryResultType;
  loading: boolean;
  activeCommunity: string;
  error: string | undefined;
  setActiveCommunity: Dispatch<React.SetStateAction<string>>;
  communities: CommunityType[];
}

interface Props {
  children: ReactChild;
  contentBlockData: VerifiedPaymentsData;
  locale: string;
}

const TracingContext = createContext<ReturnProps>(null);

export const TracingProvider: React.FC<Props> = ({
  contentBlockData,
  locale,
  children,
}: Props) => {
  if (!contentBlockData) return <span>{children}</span>;
  const [hasRefetched, setHasRefetched] = useState<boolean>(false);
  const {
    loading,
    error,
    result: { communitySummary, latestTransactions } = {},
    refetch,
  } = useQuery<QueryResultType>(TRACING_QUERY, { lang: locale });
  const [activeCommunity, setActiveCommunity] = useState<string | null>(null);

  useEffect(() => {
    // Refetch if there's an error, because the tracing is temporary unavailable
    if (error === 'PRODUCT_TRACING_DATA_UNAVAILABLE' && !hasRefetched) {
      setHasRefetched(true);
      setTimeout(refetch, 1000);
    }
  }, [error]);

  const communities = useMemo(() => {
    if (!communitySummary) return [];
    // @ts-ignore
    return communitySummary.communities.edges.map(({ node }) => node);
  }, [communitySummary]);

  return (
    <TracingContext.Provider
      value={{
        contentBlockData,
        query: { communitySummary, latestTransactions },
        loading,
        error,
        activeCommunity,
        setActiveCommunity,
        communities,
      }}
    >
      {children}
    </TracingContext.Provider>
  );
};

export default TracingContext;
