import React, { ReactChild } from 'react';
import styled from 'styled-components';
import { Video as VideoType } from 'types/types';

const VideoContainer = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 2;
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 16 / 9;

  @supports not (aspect-ratio: 16 / 9) {
    // see: http://alistapart.com/article/creating-intrinsic-ratios-for-video
    // create a 16:9 ratio -> 16/9 = 0.5625
    // padding in percentage is based on width of element
    // setting padding bottom to 56.25% will set the container height to match the 16/9 ratio
    padding-bottom: 56.25%;
    height: 100%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

interface Props {
  video: VideoType;
  className?: string;
  children?: ReactChild;
}

const Video: React.FC<Props> = ({
  video: { vimeoId },
  children,
  ...props
}: Props) => {
  return (
    <VideoContainer {...props}>
      <VideoWrapper className="video-container">
        <iframe
          className="video"
          src={`https://player.vimeo.com/video/${vimeoId}`}
          width="640"
          height="360"
          frameBorder="0"
          allow="fullscreen"
          allowFullScreen
        />
      </VideoWrapper>
      {children}
    </VideoContainer>
  );
};

export default Video;
