import React, { FC } from 'react';
import styled from 'styled-components';
import ReactSkeleton, { SkeletonProps } from 'react-loading-skeleton';

const SkeletonOverride = styled.div`
  > span {
    display: block;
    height: 0;
  }
`;

const Skeleton: FC<SkeletonProps> = (props) => {
  return (
    <SkeletonOverride>
      <ReactSkeleton {...props} />
    </SkeletonOverride>
  );
};

export default Skeleton;
