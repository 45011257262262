/* eslint-disable */
import grey_1 from 'images/sdgs/grey_1.inline.svg';
import grey_2 from 'images/sdgs/grey_2.inline.svg';
import grey_3 from 'images/sdgs/grey_3.inline.svg';
import grey_4 from 'images/sdgs/grey_4.inline.svg';
import grey_5 from 'images/sdgs/grey_5.inline.svg';
import grey_6 from 'images/sdgs/grey_6.inline.svg';
import grey_7 from 'images/sdgs/grey_7.inline.svg';
import grey_8 from 'images/sdgs/grey_8.inline.svg';
import grey_9 from 'images/sdgs/grey_9.inline.svg';
import grey_10 from 'images/sdgs/grey_10.inline.svg';
import grey_11 from 'images/sdgs/grey_11.inline.svg';
import grey_12 from 'images/sdgs/grey_12.inline.svg';
import grey_13 from 'images/sdgs/grey_13.inline.svg';
import grey_14 from 'images/sdgs/grey_14.inline.svg';
import grey_15 from 'images/sdgs/grey_15.inline.svg';
import grey_16 from 'images/sdgs/grey_16.inline.svg';
import grey_17 from 'images/sdgs/grey_17.inline.svg';
import white_1 from 'images/sdgs/white_1.inline.svg';
import white_2 from 'images/sdgs/white_2.inline.svg';
import white_3 from 'images/sdgs/white_3.inline.svg';
import white_4 from 'images/sdgs/white_4.inline.svg';
import white_5 from 'images/sdgs/white_5.inline.svg';
import white_6 from 'images/sdgs/white_6.inline.svg';
import white_7 from 'images/sdgs/white_7.inline.svg';
import white_8 from 'images/sdgs/white_8.inline.svg';
import white_9 from 'images/sdgs/white_9.inline.svg';
import white_10 from 'images/sdgs/white_10.inline.svg';
import white_11 from 'images/sdgs/white_11.inline.svg';
import white_12 from 'images/sdgs/white_12.inline.svg';
import white_13 from 'images/sdgs/white_13.inline.svg';
import white_14 from 'images/sdgs/white_14.inline.svg';
import white_15 from 'images/sdgs/white_15.inline.svg';
import white_16 from 'images/sdgs/white_16.inline.svg';
import white_17 from 'images/sdgs/white_17.inline.svg';

export default [
  {
    name: 'no-poverty',
    icon: grey_1,
    iconWhite: white_1,
    hex: '#e5233d',
  },
  {
    name: 'zero-hunger',
    icon: grey_2,
    iconWhite: white_2,
    hex: '#DDA63A',
  },
  {
    name: 'good-health-and-well-being',
    icon: grey_3,
    iconWhite: white_3,
    hex: '#4C9F38',
  },
  {
    name: 'quality-education',
    icon: grey_4,
    iconWhite: white_4,
    hex: '#C5192D',
  },
  {
    name: 'gender-equality',
    icon: grey_5,
    iconWhite: white_5,
    hex: '#FF3A21',
  },
  {
    name: 'clean-water-and-sanitation',
    icon: grey_6,
    iconWhite: white_6,
    hex: '#26BDE2',
  },
  {
    name: 'affordable-and-clean-energy',
    icon: grey_7,
    iconWhite: white_7,
    hex: '#FCC30B',
  },
  {
    name: 'decent-work-and-economic-growth',
    icon: grey_8,
    iconWhite: white_8,
    hex: '#A21942',
  },
  {
    name: 'industry-innovation-and-infrastructure',
    icon: grey_9,
    iconWhite: white_9,
    hex: '#FD6925',
  },
  {
    name: 'reduced-inequalities',
    icon: grey_10,
    iconWhite: white_10,
    hex: '#DD1367',
  },
  {
    name: 'sustainable-cities-and-communities',
    icon: grey_11,
    iconWhite: white_11,
    hex: '#FD9D24',
  },
  {
    name: 'responsible-consumption-production',
    icon: grey_12,
    iconWhite: white_12,
    hex: '#BF8B2E',
  },
  {
    name: 'climate-action',
    icon: grey_13,
    iconWhite: white_13,
    hex: '#3F7E44',
  },
  {
    name: 'life-below-water',
    icon: grey_14,
    iconWhite: white_14,
    hex: '#0A97D9',
  },
  {
    name: 'life-on-land',
    icon: grey_15,
    iconWhite: white_15,
    hex: '#56C02B',
  },
  {
    name: 'peace-justice-strong-institutions',
    icon: grey_16,
    iconWhite: white_16,
    hex: '#00689D',
  },
  {
    name: 'partnerships-for-the-goals',
    icon: grey_17,
    iconWhite: white_17,
    hex: '#19486A',
  },
];
