import React, { useState, ReactChild } from 'react';
import styled, { keyframes } from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';
import { DropDownType } from 'types/types';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scaleY(0.5) translateY(-50%);
  }
  to {
    opacity: 1;
    transform: scaleY(1) translateY(0);
  }
`;

const OptionContainer = styled.div`
  position: absolute;
  margin-top: 5px;
  padding: 4px 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadiusButton};
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.2s linear;
`;

const DropDownButton = styled.a`
  padding: 8px;
  display: flex;
  border: 1px solid #d7d7d7;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.borderRadiusButton};
`;

const Item = styled.a<{
  active: boolean;
}>`
  padding: 6px 8px 6px 5px;
  margin: 2px 0;
  display: flex;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  border-left: 4px solid #fff;

  ${({ active, theme }) =>
    active &&
    `
     border-left: 3px solid ${theme.palette.primary.main};
  `}
`;

interface Props {
  currentValue: DropDownType;
  options: DropDownType[];
  onChange: (item) => void;
  renderItems?: (option) => ReactChild;
}

const DropDown: React.FC<Props> = ({
  currentValue,
  options,
  onChange,
  renderItems,
}: Props) => {
  const [listOpen, setListOpen] = useState(false);

  const handleSelectItem = (item) => {
    onChange(item);
    setListOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setListOpen(false)}>
      <div>
        <DropDownButton onClick={() => setListOpen(!listOpen)}>
          {renderItems ? (
            renderItems(currentValue)
          ) : (
            <div>{currentValue.label} </div>
          )}
        </DropDownButton>
        {listOpen && (
          <OptionContainer>
            {options.map((option: DropDownType, index) => (
              <Item
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={() => handleSelectItem(option)}
                active={option.value === currentValue.value}
              >
                {renderItems ? renderItems(option) : <div>{option.label} </div>}
              </Item>
            ))}
          </OptionContainer>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default DropDown;
