import React from 'react';
import { useContentBlockData } from 'hooks';
import { AboutData } from 'types/types';
import { PreviewTextOverlay } from 'components/Shared';

interface Props {
  active: boolean;
}

const AboutCustomPreview: React.FC<Props> = ({ active }) => {
  const { data } = useContentBlockData<AboutData>();
  if (!data) return null;

  return (
    <PreviewTextOverlay active={active}>{data.headline}</PreviewTextOverlay>
  );
};

export default AboutCustomPreview;
