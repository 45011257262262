import React from 'react';
import styled from 'styled-components';

const Burger = styled.div<{
  inverted?: boolean;
  open?: boolean;
}>`
  display: block;
  cursor: pointer;
  padding: 8px;

  span {
    display: block;
    background: ${({ inverted, theme }) =>
      inverted ? theme.palette.text.primary : '#fff'};
    width: 26px;
    height: 2px;
    margin: 5px 0;
    opacity: 1;
    transform-orign: center;
    transition:
      transform 0.25s ease-out,
      opacity 0.25s ease-out;
    box-shadow: 1px 1px rgba(255, 255, 255, 0.75);

    &:first-of-type {
      transform: ${(props) =>
        props.open
          ? 'rotate(45deg) translate(1px, 5px)'
          : 'rotate(0) translate(0, 0);'};
      translate: ${(props) => (props.open ? '0 0' : 'center')};
    }

    &:nth-of-type(2) {
      opacity: ${(props) => (props.open ? '0' : '1')};
    }

    &:last-of-type {
      transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'rotate(0)')};
      transform-origin: ${(props) => (props.open ? '0 0' : 'center')};
    }
  }
`;

interface Props {
  open: boolean;
  className?: string;
  inverted?: boolean;
  onClick: () => void;
}

const HeaderBurger: React.FC<Props> = ({
  open,
  onClick,
  inverted,
  className,
}: Props) => (
  <Burger
    className={className}
    open={open}
    inverted={inverted}
    onClick={onClick}
  >
    <span></span>
    <span></span>
    <span></span>
  </Burger>
);

export default HeaderBurger;
