import { useTheme } from 'styled-components';
import useWindowSize from './useWindowSize';

const useBreakpoint = (breakpoint: string): boolean => {
  const { width } = useWindowSize();
  const { breakpoints } = useTheme();

  return width < breakpoints[breakpoint];
};

export default useBreakpoint;
