import React from 'react';
import styled from 'styled-components';
import { convertColorWithoutOpacity } from 'utils/color';

export const ButtonContainer = styled.div`
  margin-top: 32px;
  text-align: center;
`;

type ButtonSizes = 'small' | 'default';

export const ButtonIcon = styled(({ component, ...props }) =>
  React.cloneElement(component, { ...props })
)`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: inherit !important;
`;

const Common = styled.a<{
  size?: ButtonSizes;
  fullWidth?: boolean;
}>`
  display: inline-block;
  position: relative;
  font-size: 12px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  line-height: 1 !important;
  cursor: pointer !important;
  white-space: nowrap !important;
  padding: 12px 12px !important;

  &:disabled {
    cursor: unset;
  }

  ${({ size }) => {
    if (size === 'small') {
      return `
        padding: 8px 10px;
        font-size: 10px;
      `;
    }

    return `
      padding: 12px 16px;
      font-size: 12px;
    `;
  }}

  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100%;
  `}
`;

export const ButtonPrimary = styled(Common)<{
  disabled?: boolean;
  inverted?: boolean;
}>`
  display: inline-block;
  color: #fff !important;
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.palette.grey['600']
      : theme.palette.grey['A800']} !important;
  border-radius: ${({ theme }) => theme.borderRadiusButton} !important;
  text-align: center;
  box-sizing: border-box;

  ${({ inverted, theme }) =>
    inverted &&
    `
    background: #fff;
    color: ${theme.palette.grey['A800']};
  `}
  &:disabled {
    cursor: unset;
  }
`;

export const ButtonOutlined = styled(Common)<{
  disabled?: boolean;
}>`
  color: ${({ theme }) => theme.palette.grey['A800']} !important;
  background-color: transparent !important;
  border: ${({ theme }) =>
    `1.429px solid ${theme.palette.grey['A800']}`} !important;
  border-radius: ${({ theme }) => theme.borderRadiusButton} !important;
  text-align: center !important;

  &:disabled {
    cursor: unset;
  }
`;

export const ButtonOutlinedPrimary = styled(ButtonOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${({ theme }) =>
    `${convertColorWithoutOpacity(theme.palette.primary.main, 0.1)}`} !important;
  border: ${({ theme }) =>
    `1px solid ${theme.palette.primary.main}`} !important;
`;

export const ButtonSecondaryDark = styled.a<{
  link?: boolean;
}>`
  display: inline-block;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: transparent;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  border: ${({ link }) => (link ? 'none' : '1px solid #fff')};
  padding: ${({ link }) => (link ? '0' : '8px 16px')};
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;

  &:disabled {
    cursor: unset;
  }
`;
