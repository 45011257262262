import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import IconButton from './IconButton';
import Marker from './ProductJourney/Map/Marker/Marker';
import { X as Close } from '@styled-icons/bootstrap/X';
import { ImageType, ImageVariant } from 'types/types';

const Button = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }) => theme.palette.grey[600]};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-bottom: 18px;
`;

const StyledMarker = styled(Marker)`
  transform: translate3d(0, 0, 0) scale(0.7) !important;
`;

const Title = styled.div`
  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60%;
`;

const Location = styled.div`
  line-height: 1;
  font-weight: 300;
  font-size: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  // 28px is the width of the icon
  width: calc(100% - 28px);
`;

const Tag = styled.div`
  white-space: nowrap;
  margin-left: 8px;
  background: #d7d7d7;
  color: #000;
  padding: 6px;
  font-weight: 700;
  font-size: 14px !important;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40%;
  border-radius: 4px;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  handleClose?: () => void;
  title: string;
  componentTitle?: string;
  locationImage: ImageType | ImageVariant;
  isDesktop?: boolean;
  location: string;
}

const ImpactClaimLocation: FC<Props> = ({
  handleClose,
  title,
  locationImage,
  componentTitle,
  location,
}) => {
  return (
    <Fragment>
      <Header>
        <StyledMarker
          image={locationImage}
          active={true}
          hasBounceEffect={false}
        />
        <LocationContainer>
          <FlexBox>
            <Title>{title}</Title>
            {componentTitle && <Tag>{componentTitle}</Tag>}
          </FlexBox>
          <Location>{location}</Location>
        </LocationContainer>
        {handleClose && (
          <Button onClick={handleClose}>
            <Close size={35} />
          </Button>
        )}
      </Header>
    </Fragment>
  );
};

export default ImpactClaimLocation;
