import React, { ReactChild } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ mobile?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;

  ${({ mobile }) =>
    mobile &&
    `
      position: relative;
      z-index: 9;
      border-radius: 8px;
      width: 100%;
      min-height: 200px;
    `}
`;

interface Props {
  children: ReactChild | ReactChild[];
  mobile?: boolean;
}

const SlideComponent: React.FC<Props> = ({ children, mobile }: Props) => {
  return <Container mobile={mobile}>{children}</Container>;
};

export default SlideComponent;
