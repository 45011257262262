import iconEco from 'images/claims/claim-eco-dark.svg';
import iconEcoLight from 'images/claims/claim-eco-light.svg';
import iconEcoColor from 'images/claims/claim-eco-color.svg';
import iconSocial from 'images/claims/claim-social-dark.svg';
import iconSocialLight from 'images/claims/claim-social-light.svg';
import iconSocialColor from 'images/claims/claim-social-color.svg';

export const impactCategoryConfig = {
  ECOLOGICAL: {
    icon: iconEco,
    activeIcon: iconEcoLight,
    coloredIcon: iconEcoColor,
    color: '#2DB875',
    catalogQueryAlias: 'socialItems',
  },
  SOCIAL: {
    icon: iconSocial,
    activeIcon: iconSocialLight,
    coloredIcon: iconSocialColor,
    color: '#FFB801',
    catalogQueryAlias: 'ecologicalItems',
  },
};
