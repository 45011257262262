import contentBlockConfig from 'constants/contentBlockConfig';
import { useListenerToBusinessApp } from 'hooks';
import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from 'react';
import sortBy from 'lodash/sortBy';
import { ActiveItemUpdate, ContentTypeEnum } from 'types/types';

const ActiveItemContext = React.createContext(null);
const ActiveItemConsumer = ActiveItemContext.Consumer;

interface Props {
  children: ReactNode;
}

type ScrollDirection = 'top' | 'up' | 'down' | 'bottom';

const ActiveItemProvider: React.FC<Props> = ({ children }) => {
  const [activeItem, setActiveItem] = useState<string | null>();
  const [openContentBlocks, setOpenContentBlocks] = useState<ContentTypeEnum[]>(
    []
  );
  const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
  const [preventAnimation, setPreventAnimation] = useState<boolean>(false);

  const [activeIntersections, setActiveIntersections] = useState<
    ActiveItemUpdate[]
  >([]);

  const [y, setY] = useState(0);
  const [scrollDirection, setScrollDirection] =
    useState<ScrollDirection>('top');

  // Sets the scroll direction based on the scroll behaviour and the window limits.
  const handleScrollNavigation = useCallback(
    (e) => {
      const target = e.currentTarget;
      const isAtTop = window.scrollY < 100;
      const isAtBottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.body.offsetHeight;

      if (isAtTop) {
        setScrollDirection('top');
      } else if (isAtBottom) {
        setScrollDirection('bottom');
      } else if (y > target.scrollY) {
        setScrollDirection('up');
      } else if (y < target.scrollY) {
        setScrollDirection('down');
      }
      setY(target.scrollY);
    },
    [y]
  );

  // Adds a scroll listener to the window to track if a user is scrolling up or down.
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleScrollNavigation);

    return () => {
      window.removeEventListener('scroll', handleScrollNavigation);
    };
  }, [handleScrollNavigation]);

  // Updates the intersection status, filters and replaces the old status with the new update.
  const updateItemStatus = (update: ActiveItemUpdate) => {
    setActiveIntersections([
      ...activeIntersections.filter(
        (intersection) => intersection.block !== update.block
      ),
      update,
    ]);
  };

  // Filters and sorts the active sections so they are sorted in indexed order from small to bigger index.
  const activeSections: Array<ActiveItemUpdate> | null = useMemo(() => {
    if (!activeIntersections) {
      return null;
    }

    const activeItems = activeIntersections.filter(
      (intersection) => intersection.isIntersecting === true
    );

    // sorts by index, 0 to x
    return sortBy(activeItems, 'index');
  }, [activeIntersections, scrollDirection]);

  // Everytime the activeSections array changes, we determine the active item again.
  // The active item is determined based on top/bottom position and the scroll direction.
  useEffect(() => {
    if (activeSections && activeSections.length > 0) {
      const firstItem: string = activeSections[0].block;
      const lastItem: string = activeSections[activeSections.length - 1].block;
      if (scrollDirection === 'top') {
        // if the viewport is at the top, we just select the first section.
        setActiveItem(firstItem);
      } else if (scrollDirection === 'bottom') {
        // if the viewport is at the bottom, we can safely select the last section.
        setActiveItem(lastItem);
      } else if (scrollDirection === 'down') {
        // if the user is scrolling down, we always chose the item with the lowest index ( the topmost item of the active ones )
        setActiveItem(firstItem);
      } else {
        // up
        // if the user is scrolling up, we always chose the item with the highest index ( the lowest item of the active ones )
        setActiveItem(lastItem);
      }
    }
  }, [activeSections]);

  const onEventReceived = (data) => {
    if (
      data === 'refetch' ||
      typeof data !== 'string' ||
      data.includes('ready')
    ) {
      return;
    }

    if (
      Object.keys(contentBlockConfig)
        .map((key) => key.toLowerCase())
        .includes(data.toLowerCase())
    ) {
      setAccordionOpen(true);
      if (!preventAnimation) setPreventAnimation(true);
      setOpenContentBlocks([data as ContentTypeEnum]);
    } else {
      setAccordionOpen(false);
      if (!preventAnimation) setPreventAnimation(true);
      setOpenContentBlocks([]);
    }
  };

  const preSetOpenContentBlocks = (blocks: ContentTypeEnum[]) => {
    if (preventAnimation) setPreventAnimation(false);
    setOpenContentBlocks(blocks);
  };

  useListenerToBusinessApp(onEventReceived);

  return (
    <ActiveItemContext.Provider
      value={{
        activeItem,
        setActiveItem,
        openContentBlocks,
        setOpenContentBlocks: preSetOpenContentBlocks,
        accordionOpen,
        setAccordionOpen,
        preventAnimation,
        updateItemStatus,
      }}
    >
      {children}
    </ActiveItemContext.Provider>
  );
};

export { ActiveItemProvider, ActiveItemConsumer };

export default ActiveItemContext;
