import graphQLClient from './graphql-client';
import {
  ANALYTICS_MUTATION,
  ANALYTICS_BEHAVIOR_MUTATION,
} from 'graphql/mutations';
import formatISO from 'date-fns/formatISO';
import { AnalyticsPluginType, AnalyticsPayloadType } from '../types/types';

const analyticsPlugin = (): AnalyticsPluginType => ({
  name: 'seedtrace-analytics-plugin',

  // callback for triggering page view. We send user data to our server when user loads page
  page: ({ payload }) => {
    const {
      properties: { productId, byQr = '', sessionId, visitorId },
    } = payload;

    const input = {
      visitorId,
      productId,
      sessionId,
      timestamp: formatISO(Date.now()),
      eventPayload: { byQr },
    };

    graphQLClient.request(ANALYTICS_MUTATION, { input });
  },

  // callback for tracking event. We send event to our server
  track: ({ payload }: { payload: AnalyticsPayloadType }) => {
    const {
      event,
      properties: { visitorId, productId, sessionId, ...props },
      meta: { ts },
    } = payload;

    const input = {
      visitorId,
      productId,
      sessionId,
      eventType: event,
      timestamp: formatISO(ts),
      eventPayload: {
        ...props,
      },
    };

    graphQLClient.request(ANALYTICS_BEHAVIOR_MUTATION, { input });
  },

  loaded: () => true,
});

export default analyticsPlugin;
