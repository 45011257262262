import { useContext } from 'react';
import BrowserContext, {
  BrowserContextReturn,
} from 'components/Contexts/BrowserContext';

const useBrowser = (): BrowserContextReturn => {
  const { isMobile, isSafari } = useContext(BrowserContext);

  return { isMobile, isSafari };
};

export default useBrowser;
