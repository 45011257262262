import React from 'react';
import styled from 'styled-components';
import { ChevronLeft } from '@styled-icons/bootstrap';

const Button = styled.button<{ color?: string }>`
  display: flex;
  align-items: center;
  border: none;
  padding: 8px 16px;
  text-decoration: none !important;
  background: transparent !important;
  color: ${({ theme, color }) =>
    color ? color : theme.palette.text.main} !important;
  font-size: 14px !important;
  cursor: pointer !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;

  span {
    margin-left: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.grey['A500']};
  }
`;

const Icon = styled(ChevronLeft)`
  margin-right: 4px;
`;

interface Props {
  action: () => void;
  label: string;
  color?: string;
}

const BackLink: React.FC<Props> = ({ action, label, color }: Props) => {
  return (
    <Button onClick={action} color={color}>
      <Icon size={14} />
      <span>{label}</span>
    </Button>
  );
};

export default BackLink;
