export enum ProductJourneyPages {
  PRODUCT_JOURNEY = 'productJourney',
  IMPACT_CLAIM_LIST = 'impactClaims',
  IMPACT_CLAIM = 'impactClaim',
  SITE_CLUSTER_OVERVIEW = 'siteClusterOverview',
  DOCUMENTS = 'documents',
  BATCH_TRACING = 'batchTracing',
  SITE_BATCH_TRACING = 'siteBatchTracing',
}

export const geProductPagesPageIndex = (name: ProductJourneyPages): number => {
  return {
    [ProductJourneyPages.PRODUCT_JOURNEY]: 0,
    [ProductJourneyPages.IMPACT_CLAIM_LIST]: 1,
    [ProductJourneyPages.IMPACT_CLAIM]: 2,
    [ProductJourneyPages.DOCUMENTS]: 3,
  }[name || ProductJourneyPages.PRODUCT_JOURNEY];
};
