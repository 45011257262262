import 'intersection-observer';
import ResizeObserverPolyfill from 'resize-observer-polyfill';
import React, { Fragment, useEffect } from 'react';
import { gridTheme } from 'utils/theme';
import i18n from 'utils/i18n';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import { QueryParamProvider } from 'components/Contexts';
import { ActiveItemProvider } from 'components/Contexts';
import { I18nextProvider } from 'react-i18next';
import smoothscroll from 'smoothscroll-polyfill';
import 'lazysizes';
import Bugsnag from '@bugsnag/js';
import { useRouter } from 'next/router';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-multi-carousel/lib/styles.css';
import theme from 'utils/theme';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import styled, { ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';
import 'utils/app.css';

/**
 * Workaround to fix ThemeProvider issue https://github.com/styled-components/styled-components/issues/3738
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GlobalThemeProvider: any = ThemeProvider;

Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.DEPLOY_ENV,
  enabledReleaseStages: ['staging', 'production'],
});

type Props = {
  Component: React.FC;
  pageProps: Record<string, unknown>;
};

const SpecialModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 9999999;
`;

const App: React.FC<Props> = ({ Component, pageProps }: Props) => {
  useEffect(() => {
    smoothscroll.polyfill();
    window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
  }, []);
  const router = useRouter();
  const { locale = '' } = router.query;

  return (
    // @ts-expect-error
    <Fragment key={locale}>
      <QueryParamProvider>
        <GlobalThemeProvider theme={theme}>
          <GridThemeProvider gridTheme={gridTheme}>
            <I18nextProvider i18n={i18n}>
              <ModalProvider backgroundComponent={SpecialModalBackground}>
                <ActiveItemProvider>
                  <Component {...pageProps} />
                </ActiveItemProvider>
              </ModalProvider>
            </I18nextProvider>
          </GridThemeProvider>
        </GlobalThemeProvider>
      </QueryParamProvider>
    </Fragment>
  );
};

export default App;
