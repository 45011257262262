export const BREAKPOINTS = {
  xl: 900,
  lg: 680,
  mobile: 679,
};

export const device = {
  mobile: `(max-width: ${BREAKPOINTS.mobile}px)`,
  desktop: `(min-width: ${BREAKPOINTS.lg}px)`,
  desktopLarge: `(min-width: ${BREAKPOINTS.xl}px)`,
};
