import React, { ReactChild } from 'react';

const ContentBlockDataContext = React.createContext(null);
const ContentBlockDataConsumer = ContentBlockDataContext.Consumer;

interface Props {
  children: ReactChild;
  data: Record<string, unknown>;
}

const ContentBlockDataProvider: React.FC<Props> = ({
  children,
  data,
}: Props) => {
  return (
    <ContentBlockDataContext.Provider value={{ data }}>
      {children}
    </ContentBlockDataContext.Provider>
  );
};

export { ContentBlockDataProvider, ContentBlockDataConsumer };

export default ContentBlockDataContext;
