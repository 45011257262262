import { gql } from 'graphql-request';
import {
  ABOUT_FRAGMENT,
  COMPANY_DESIGN_FRAGMENT,
  FEEDBACK_BLOCK_FRAGMENT,
  IMPACT_FACTS_FRAGMENT,
  MORE_INFO_BLOCK_FRAGMENT,
  PRODUCTS_BLOCK_FRAGMENT,
  VERIFIED_PAYMENTS_BLOCK_FRAGMENT,
} from './contentBlocks.fragment';
import {
  IMAGE_TYPE_FRAGMENT,
  IMAGE_VARIANT_FRAGMENT,
  MEDIA_LIST_TYPE_FRAGMENT,
  VIDEO_FRAGMENT,
} from './media.fragment';
import { SUPPLY_CHAIN_FRAGMENT } from './supplyChain.fragment';

export const PRODDUCT_LANGUAGE_CONFIG = gql`
  fragment languageConfigValues on ProductLanguageConfig {
    enabled
    default
  }
`;

export const COMPANY_FRAGMENT = gql`
  fragment companyValues on ProductCompanyData {
    name
    logo {
      url
    }
    website
    onlineShop
    address {
      city
    }
    gaTrackingId
    social {
      facebook
      instagram
      twitter
    }
    design {
      ...companyDesign
    }
  }

  ${COMPANY_DESIGN_FRAGMENT}
`;

const SDG_FRAGMENT = gql`
  fragment sdgValues on CmsSustainableDevGoal {
    title
    description
    number
    color
    iconUrl
  }
`;

const IMPACT_CATALOG_ITEM_FRAGMENT = gql`
  fragment impactCatalogItemValues on CmsImpactCatalogItem {
    id
    category {
      type
    }
    title
    definition
    iconUrl
    sustainableDevGoals {
      ...sdgValues
    }
    acceptanceCriteria {
      description
    }
    docRequirements {
      description
    }
  }
  ${SDG_FRAGMENT}
`;

export const DOCUMENT_FRAGMENT = gql`
  fragment documentValues on Document {
    id
    path
    url
    title
    createdTimestamp
    expiryDate
    category
    issuedDate
    visibility
  }
`;

export const IMPACT_CLAIM = gql`
  fragment impactClaimValues on ImpactClaim {
    id
    createdTimestamp
    status
    attachmentCount
    attachments {
      edges {
        node {
          ...documentValues
        }
      }
    }
    impactCatalogItem {
      ...impactCatalogItemValues
    }
    component {
      id
      title
    }
  }
  ${IMPACT_CATALOG_ITEM_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
`;

export const ALL_PRODUCT_INFO_FRAGMENT = gql`
  fragment allProductInfoValues on ProductSerialized {
    id
    productId
    webshopUrl
    title
    image {
      ...imageType
    }
    slug
    currentLang
    offboardingRedirect
    impactClaims {
      edges {
        node {
          ...impactClaimValues
        }
      }
    }
    publishedLanguages
    languageConfig {
      default
      enabled
    }
    company {
      ...companyValues
    }
    supplyChain {
      ...supplyChainValues
    }
    traceableRawMaterialIds: traceableRawMaterials {
      id
    }
    latestBatchTracingDate
    contentBlocks {
      ... on AboutBlock {
        ...aboutBlock
      }
      ... on ImpactFactsBlock {
        ...impactFactsBlock
      }
      ... on ProductJourneyBlock {
        id
        contentType
        enabled
      }
      ... on VerifiedPaymentsBlock {
        ...VerifiedPaymentsBlockValues
      }
      ... on ProductsBlock {
        ...productsBlock
      }
      ... on MoreInfoBlock {
        ...moreInfoBlock
      }
      ... on FeedbackBlock {
        ...feedbackBlock
      }
    }
  }
  ${ABOUT_FRAGMENT}
  ${IMPACT_FACTS_FRAGMENT}
  ${PRODUCTS_BLOCK_FRAGMENT}
  ${MORE_INFO_BLOCK_FRAGMENT}
  ${FEEDBACK_BLOCK_FRAGMENT}
  ${VERIFIED_PAYMENTS_BLOCK_FRAGMENT}
  ${IMAGE_TYPE_FRAGMENT}
  ${MEDIA_LIST_TYPE_FRAGMENT}
  ${VIDEO_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
  ${IMPACT_CLAIM}
  ${COMPANY_FRAGMENT}
  ${SUPPLY_CHAIN_FRAGMENT}
`;
