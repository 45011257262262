import React, { useRef, useState } from 'react';
import { ImpactFactType } from 'types/types';
import { Collapse } from 'react-collapse';
import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown';
import styled from 'styled-components';
import SdgPills from './SdgPills';
import sdgConfig from 'constants/sdgConfig';
import { useTranslation } from 'react-i18next';
import { HtmlField } from 'components/Shared';

const StyledChevron = styled(ChevronDown)<{
  active: boolean;
}>`
  transform: scaleY(1);
  transition: transform 0.25s linear;

  ${({ active }) =>
    active &&
    `
    transform: scaleY(-1);
  `}
`;

const AccordionContainer = styled.div`
  margin-top: 16px;

  .ReactCollapse--collapse {
    transition: height 250ms;
  }
`;

const AccordionItem = styled.div`
  margin-bottom: 2px;

  &:last-of-type .accordion-item-title {
    border-bottom: none;
  }
`;

const ContentContainer = styled.div`
  padding: 16px;
  background: #fff;
  margin-bottom: 4px;
  background: ${({ theme }) => theme.palette.grey[200]};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
`;

const TitleItem = styled.div<{
  active: boolean;
}>`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: #fff;
  font-weight: 600;
  background: ${({ theme }) => theme.palette.grey[200]};
  border-radius: ${({ theme }) => theme.borderRadius};

  ${({ active }) =>
    active &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`;

const Supported = styled.h5`
  margin: 16px 0 8px;
`;

interface Props {
  items: ImpactFactType[];
  isMobile?: boolean;
}

const ImpactFactsAccordion: React.FC<Props> = ({ items, isMobile }: Props) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const scrollRef = useRef<HTMLDivElement>();

  const scrollToImpact = () => {
    if (!scrollRef?.current) return;

    scrollRef.current.scrollIntoView();
  };

  const handleClickItem = ({ active, index }) => {
    if (isMobile) {
      scrollToImpact();
    }
    setActiveIndex(active ? null : index);
  };

  return (
    <AccordionContainer ref={scrollRef}>
      {items.map(
        ({ title, description, sdgs }: ImpactFactType, index: number) => {
          const active = activeIndex === index;
          const sdgArray = sdgs.map((sdg) => {
            const { iconWhite, name, hex } = sdgConfig[sdg];
            return {
              title: t(`sdgs.${name}`),
              icon: iconWhite,
              color: hex,
              number: sdg,
            };
          });

          return (
            // eslint-disable-next-line react/no-array-index-key
            <AccordionItem key={`accordion-item-${index}`}>
              <TitleItem
                active={active}
                className="accordion-item-title"
                onClick={() => {
                  handleClickItem({ active, index });
                }}
              >
                <span>{title}</span>
                <StyledChevron size={16} active={active} />
              </TitleItem>
              <Collapse isOpened={active}>
                <ContentContainer>
                  <HtmlField>{description}</HtmlField>
                  <Supported>{t('supported-sdgs')}</Supported>
                  <SdgPills sdgs={sdgArray} />
                </ContentContainer>
              </Collapse>
            </AccordionItem>
          );
        }
      )}
    </AccordionContainer>
  );
};

export default ImpactFactsAccordion;
