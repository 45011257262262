import styled from 'styled-components';

export default styled.div<{ active: boolean }>`
  position: absolute;
  z-index: 3;
  padding: 8px 12px;
  left: 20px;
  bottom: 20px;
  background: ${({ theme }) => theme.palette.grey['A800']};
  border-radius: ${({ theme }) => theme.borderRadius};
  color: #fff;
  /* What makes Koa different? */
  font-weight: 700;
  font-size: 14px;
  display: flex;
  text-shadow: 0px 2px 16px rgba(0, 0, 0, 0.11);
  margin-right: 8px;

  transition: opacity 0.2s ease;
  opacity: ${({ active }) => (active ? 0.8 : 0)};
`;
