import { GraphQLClient } from 'graphql-request';

const endpoint = process.env.NEXT_PUBLIC_GQL_ENDPOINT;
const apiKey = process.env.NEXT_PUBLIC_GQL_API_KEY;

const client = new GraphQLClient(`${endpoint}`, {
  headers: {
    authorization: apiKey,
  },
});

export default client;
