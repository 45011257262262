import { useEffect, useState } from 'react';

const useWindowScrolling = (timeout = 1000): boolean => {
  const [scrolling, setScrolling] = useState<boolean>(false);

  useEffect(() => {
    let didScroll = false;

    window.onscroll = () => {
      didScroll = true;
      setScrolling(true);
    };

    setInterval(() => {
      if (didScroll) {
        didScroll = false;
        setScrolling(false);
      }
    }, timeout);
  }, []);

  return scrolling;
};

export default useWindowScrolling;
