import React from 'react';
import styled from 'styled-components';
import { Search } from '@styled-icons/bootstrap/Search';
import { X as Close } from '@styled-icons/bootstrap/X';

const Container = styled.div`
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
`;

const StyledInput = styled.input`
  padding: 10px 24px 10px 14px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]} !important;
  width: 100%;

  &:focus,
  &:focus-visible {
    outline: none !important;
    border-color: ${({ theme }) => theme.palette.primary.main} !important;
  }
`;

const RemoveButton = styled(Close)`
  cursor: pointer;
`;

interface Props {
  placeholder: string;
  onChange: (value: string) => void;
  value: string;
  className?: string;
  autofocus?: boolean;
  name?: string;
}

const SearchField: React.FC<Props> = ({
  onChange,
  placeholder = '',
  value = '',
  className = '',
  autofocus = false,
  name = '',
}: Props) => {
  return (
    <Container className={className}>
      <StyledInput
        onChange={(e) => onChange(e.target.value)}
        type="text"
        value={value}
        placeholder={placeholder}
        autoFocus={autofocus}
        name={name}
      />
      <IconContainer onClick={() => value && onChange('')}>
        {value ? <RemoveButton size={18} /> : <Search size={14} />}
      </IconContainer>
    </Container>
  );
};

export default SearchField;
