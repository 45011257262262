import React, { ReactChild } from 'react';
import styled from 'styled-components';
import CertificateMultiIcon from 'images/claims/certificates.svg';
import CertificateIcon from 'images/claims/certificate.svg';
import TagIcon from 'images/icons/tag.svg';
import CalendarIcon from 'images/icons/calendar.svg';
import ExternalLinkIcon from 'images/icons/external_link.svg';
import { useTheme } from 'styled-components';
import { ClaimAttachmentType, DocumentVisibilityStatus } from 'types/types';
import { useTranslation } from 'react-i18next';
import { formatDateString } from 'utils/date';

const Container = styled.a<{
  isActive: boolean;
  disabled: boolean;
}>`
  display: flex;
  justify-content: flex;
  align-items: center;
  grid-gap: 10px;
  background: ${({ theme, disabled }) =>
    disabled ? theme.palette.grey['200'] : 'white'};
  box-shadow: ${({ theme, disabled }) =>
    disabled ? 'none' : theme.shadows[0]};
  border-left: 8px solid transparent;
  width: 100%;
  border-radius: 6px;
  padding: 8px 16px;
  box-sizing: border-box;
  text-decoration: none;
  overflow: hidden;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${({ isActive, theme }) =>
    isActive &&
    `
    border-left-color: ${theme.palette.grey.A800};
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const Title = styled.span<{
  bold: boolean;
}>`
  color: #000;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  font-size: 14px;
`;

const SubTitle = styled.span<{
  bold?: boolean;
}>`
  color: ${({ theme }) => theme.palette.grey.A300};
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  font-size: 11px;
  display: flex;
  align-items: center;
  grid-gap: 14px;
`;

const DocumentIconContainer = styled.div`
  min-width: 24px;
  display: flex;
  justify-content: center;
`;

const SubtitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 4px;

  span {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface Props {
  children: ReactChild;
  multi?: boolean;
  onDocumentClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  document?: ClaimAttachmentType;
  active?: boolean;
}

const DocumentContainer: React.FC<Props> = ({
  children,
  multi = false,
  onDocumentClick,
  document,
  active = false,
}: Props) => {
  const { t } = useTranslation();
  const {
    palette: {
      primary: { main: themeColor },
      grey: { A300 },
    },
    backgrounds: { grey },
  } = useTheme();

  const documentIsPublic =
    document?.visibility === DocumentVisibilityStatus.PUBLIC;
  const canClick = multi || documentIsPublic;

  return (
    <Container
      href={canClick ? document?.url : ''}
      disabled={!canClick}
      target={document && '_blank'}
      onClick={canClick ? onDocumentClick : undefined}
      isActive={active}
      data-cy="proof-document"
    >
      <DocumentIconContainer>
        {multi ? (
          <CertificateMultiIcon color={themeColor} />
        ) : (
          <CertificateIcon color={themeColor} />
        )}
      </DocumentIconContainer>
      <TitleContainer>
        <Title bold={!!document}>
          {document && !documentIsPublic
            ? t('impact-claims.private-document')
            : children}
        </Title>
        {!multi &&
          (documentIsPublic ? (
            document && (
              <SubTitle bold>
                {document.category && (
                  <SubtitleWithIcon>
                    <TagIcon color={A300} />
                    <span title={t(`document-category.${document.category}`)}>
                      {t(`document-category.${document.category}`)}
                    </span>
                  </SubtitleWithIcon>
                )}
                {document.issuedDate && (
                  <SubtitleWithIcon>
                    <CalendarIcon color={A300} />
                    <span>{formatDateString(document.issuedDate)}</span>
                  </SubtitleWithIcon>
                )}
              </SubTitle>
            )
          ) : (
            <SubTitle>{t('impact-claims.private-document-subtitle')}</SubTitle>
          ))}
      </TitleContainer>
      {canClick && <ExternalLinkIcon color={grey} />}
    </Container>
  );
};

export default DocumentContainer;
