import { gql } from 'graphql-request';
import {
  COMPANY_DESIGN_FRAGMENT,
  VERIFIED_PAYMENTS_BLOCK_FRAGMENT,
} from './fragments/contentBlocks.fragment';
import {
  ALL_PRODUCT_INFO_FRAGMENT,
  IMPACT_CLAIM,
  PRODDUCT_LANGUAGE_CONFIG as PRODUCT_LANGUAGE_CONFIG,
} from './fragments/general.fragment';
import {
  IMAGE_VARIANT_FRAGMENT,
  MEDIA_LIST_TYPE_FRAGMENT,
  VIDEO_FRAGMENT,
} from './fragments/media.fragment';
import { SUPPLY_CHAIN_FRAGMENT } from './fragments/supplyChain.fragment';
import { PRODUCT_BATCH_FRAGMENT } from './fragments/batch.fragments';

export const INTEGRATION_QUERY = gql`
  query PageQuery($id: UUID!, $published: Boolean!, $lang: String) {
    allProductInfoById(id: $id, published: $published, lang: $lang) {
      productId
      currentLang
      publishedLanguages
      languageConfig {
        ...languageConfigValues
      }
      impactClaims {
        edges {
          node {
            ...impactClaimValues
          }
        }
      }
      supplyChain {
        ...supplyChainValues
      }
      traceableRawMaterialIds: traceableRawMaterials {
        id
      }
      latestBatchTracingDate
      company {
        design {
          ...companyDesign
        }
        gaTrackingId
        integrationSettings {
          widgetType
          showClaims
        }
        social {
          facebook
          instagram
          twitter
        }
      }
    }
  }
  ${IMAGE_VARIANT_FRAGMENT}
  ${COMPANY_DESIGN_FRAGMENT}
  ${PRODUCT_LANGUAGE_CONFIG}
  ${IMPACT_CLAIM}
  ${MEDIA_LIST_TYPE_FRAGMENT}
  ${VIDEO_FRAGMENT}
  ${SUPPLY_CHAIN_FRAGMENT}
`;

export const PRODUCT_PAGE_QUERY = gql`
  query PageQuery($slug: String!, $published: Boolean!, $lang: String) {
    allProductInfo(slug: $slug, published: $published, lang: $lang) {
      ...allProductInfoValues
    }
  }
  ${ALL_PRODUCT_INFO_FRAGMENT}
`;

export const PRODUCT_PAGE_QUERY_BY_ID = gql`
  query PageQueryById($id: UUID!, $published: Boolean!, $lang: String) {
    allProductInfoById(id: $id, published: $published, lang: $lang) {
      ...allProductInfoValues
    }
  }
  ${ALL_PRODUCT_INFO_FRAGMENT}
`;

export const TRACING_QUERY = gql`
  query TracingQuery($lang: Language) {
    communitySummary(lang: $lang) {
      center {
        lat
        lng
      }
      bbox
      count
      totalPriceInUsd
      totalPriceInGhs
      totalQuantityInKg
      totalProfitedFarmers
      communities {
        edges {
          node {
            id
            name
            description
            locationLatitude
            locationLongitude
            totalFarmers
            totalPriceInUsd
            totalPriceInGhs
            totalQuantityInKg
          }
        }
      }
    }
    latestTransactions {
      hashes {
        url
        hash
        priceInGhs
        priceInUsd
        quantityInKg
        farmerName
        timestamp
      }
    }
  }
`;

export const TRANSACTIONS_QUERY = gql`
  query TransactionsQuery($id: ID!, $page: Int) {
    communityTransactions(page: $page, id: $id) {
      nextPage
      count
      hashes {
        url
        hash
        priceInUsd
        priceInGhs
        quantityInKg
        farmerName
        timestamp
      }
    }
  }
`;

// Used for now only on the slider widget
export const GET_COMPANY_PRODUCTS = gql`
  query Query {
    companyProducts {
      edges {
        node {
          id
          title
          image {
            url
            crop {
              x
              y
              width
              height
            }
          }
        }
      }
    }
    company {
      design {
        ...companyDesign
      }
      gaTrackingId
    }
  }
  ${COMPANY_DESIGN_FRAGMENT}
`;

export const GET_VERIFIED_PAYMENTS_WIDGET_DATA = gql`
  query VerifiedPaymentWidgetQuery(
    $slug: String!
    $published: Boolean!
    $lang: String
  ) {
    allProductInfo(slug: $slug, published: $published, lang: $lang) {
      contentBlocks {
        ... on VerifiedPaymentsBlock {
          ...VerifiedPaymentsBlockValues
        }
      }
      company {
        design {
          palette {
            primary
          }
        }
        gaTrackingId
      }
    }
  }
  ${VERIFIED_PAYMENTS_BLOCK_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const GET_BATCH_DATA = gql`
  query GetBatchData(
    $productId: UUID!
    $batchTracingDate: Date!
    $lang: Language
  ) {
    productBatchData(
      productId: $productId
      batchTracingDate: $batchTracingDate
      lang: $lang
    ) {
      ...productBatchValues
    }
  }
  ${PRODUCT_BATCH_FRAGMENT}
`;
