import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { eventType } from 'constants/analytics';
import { TrackStepsArgs } from 'components/Shared/ProductJourney/types';
import analyticsInstance from 'utils/analytics';
import { AnalyticsInstance } from 'analytics';
import { AnalyticsFake } from 'types/types';

interface Arguments {
  preview: boolean;
  byQr: string;
  productId: string;
  trackingAllowed: boolean;
  gaTrackingId: string;
}

interface Return {
  sessionActive: boolean;
  sessionId: string;
  visitorId: string;
  trackSteps: (args: TrackStepsArgs) => void;
  analytics: AnalyticsInstance | AnalyticsFake;
}

// hook to share tracking functionality between product page and integrations
const useTracking = ({
  preview,
  byQr = '',
  productId,
  trackingAllowed,
  gaTrackingId,
}: Arguments): Return => {
  const analytics = preview
    ? {
        identify: () => ({}),
        page: () => ({}),
        track: () => ({}),
        user: () => ({}),
        getState: () => ({}),
      }
    : analyticsInstance(gaTrackingId);
  const [sessionId] = useState<string>(uuid());
  const [sessionActive, setSessionActive] = useState<boolean>(false);
  const [visitorId] = useState<string>(analytics.user().anonymousId);

  // tracking the product journey steps
  const trackSteps = ({ totalSteps, stepIndex }: TrackStepsArgs) => {
    if (!trackingAllowed) return;
    analytics.track(eventType.step, {
      visitorId,
      productId,
      sessionId,
      totalSteps,
      stepIndex,
    });
  };

  // checking whether a session is active or not
  useEffect(() => {
    const pageTrackTimeout = setTimeout(() => {
      analytics.page({
        visitorId,
        productId,
        sessionId,
        byQr,
      });
    }, 2000);

    const setActive = () => setSessionActive(true);
    const setInactive = () => setSessionActive(false);

    if (!document.hidden) setActive();

    window.addEventListener('focus', setActive);
    window.addEventListener('blur', setInactive);
    window.addEventListener('beforeunload', () =>
      clearTimeout(pageTrackTimeout)
    );

    return () => {
      window.removeEventListener('focus', setActive);
      window.removeEventListener('blur', setInactive);
    };
  }, []);

  useEffect(() => {
    if (!trackingAllowed) return;

    analytics.identify('', {
      productId,
    });
  }, [trackingAllowed]);

  return {
    sessionActive,
    sessionId,
    trackSteps,
    visitorId,
    analytics,
  };
};

export default useTracking;
