import React, { ReactChild } from 'react';
import { InfoCircleFill } from '@styled-icons/bootstrap/InfoCircleFill';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const TooltipWrapper = styled.div`
  .tooltip {
    background: #fff;
    color: ${({ theme }) => theme.palette.grey['A800']};
    max-width: 200px;
    box-shadow: rgb(0 0 0 / 11%) 0px 2px 16px 0px;
    opacity: 1;
    border-radius: 3px;
    line-height: 1.2;
    padding: 8px 10px;
  }
`;

interface Props {
  children?: ReactChild;
  tooltipText: string;
}

const Tooltip: React.FC<Props> = ({ children, tooltipText }: Props) => {
  return (
    <TooltipWrapper data-tip={tooltipText} data-iscapture="true">
      {!!children ? (
        <span>{children}</span>
      ) : (
        <div>
          <InfoCircleFill size={20} color="#D7D7D7" />
        </div>
      )}
      <ReactTooltip
        multiline={true}
        effect="solid"
        className="tooltip"
        arrowColor="transparent"
      />
    </TooltipWrapper>
  );
};

export default Tooltip;
