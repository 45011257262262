import { MutableRefObject, useEffect, useState } from 'react';

const useHasOverflow = (ref: MutableRefObject<Element>) => {
  const [isScrolledToStart, setIsScrolledToStart] = useState<boolean>(true);
  const [hasOverflow, setIsOverflow] = useState<boolean>(undefined);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState<boolean>(false);

  const handleResize = () => {
    const { current } = ref;
    if (current) {
      const hasOverflow = current.scrollWidth > current.clientWidth;
      setIsOverflow(hasOverflow);
      handleScroll();
    }
  };

  const handleScroll = () => {
    const { current } = ref;
    if (current) {
      setIsScrolledToStart(current.scrollLeft === 0);
      setIsScrolledToEnd(
        current.scrollLeft >= current.scrollWidth - current.clientWidth
      );
    }
  };

  useEffect(() => handleResize(), [ref, ref.current?.scrollLeft]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [ref.current]);

  return { isScrolledToStart, hasOverflow, isScrolledToEnd };
};

export default useHasOverflow;
