import Typography from 'typography';

interface Typography {
  baseFontSize: string;
  baseLineHeight: number;
  baseFontWeight: number;
  headerFontFamily: [string];
  bodyFontFamily: [string];
}

export const initialTypography = (
  typographyHeader: string,
  typographyBody: string
): Typography => {
  return new Typography({
    baseFontSize: '16px',
    baseLineHeight: 1.666,
    baseFontWeight: 300,
    headerFontFamily: [
      typographyHeader,
      'Helvetica Neue',
      'Segoe UI',
      'Helvetica',
      'Arial',
      'sans-serif',
    ],
    bodyFontFamily: [typographyBody, 'sans-serif'],
  });
};

export const getGoogleFontsLink = (fontFamily: string): string => {
  return `https://fonts.googleapis.com/css2?family=${fontFamily}:wght@300;400;600;700&&display=swap`;
};
