export const pingInterval = 3500;

export const eventType = {
  page: 'PAGE',
  ping: 'PING',
  click: 'CLICK',
  step: 'STEP',
};

export const componentIds = {
  externalLink: 'EXTERNAL_LINK',
  mainScreen: 'MAIN_SCREEN',
};

export const clickedIds = {
  productSummary: 'ProductSummary:website-link',
  about: 'About:learn-more-button',
  feedback: 'Feedback:send-button',
  onlineShop: 'Menu:visit-online-shop-link',
  step: (step: string): string => `${step}:learn-more-button`,
  product: (product: string): string => `${product}:online-shop-button`,
  social: ['Menu:facebook-link', 'Menu:instagram-link', 'Menu:twitter-link'],
  webshop: 'ProductSummary:webshop-link',
};
