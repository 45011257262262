import React, { FC } from 'react';
import { MediaType } from 'types/types';
import Image from './Image';
import Video from './Video';

interface Props {
  media?: MediaType;
  className?: string;
  onClick?: () => void;
  attributionInside?: boolean;
  stepIndex?: number;
  displaySkeleton?: boolean;
  gradientOverlay?: boolean;
}

const Media: FC<Props> = ({
  media,
  className = '',
  onClick = () => {}, // eslint-disable-line
  stepIndex,
  displaySkeleton = false,
  gradientOverlay = false,
  ...props
}) => {
  if (!media) {
    return <Image image={null} {...props} />;
  }

  if (media.__typename === 'ImageType' || media.__typename === 'ImageVariant') {
    return (
      <Image
        stepIndex={stepIndex}
        onClick={onClick}
        className={className}
        image={media}
        gradientOverlay={gradientOverlay}
        displaySkeleton={displaySkeleton}
        {...props}
      />
    );
  }

  if (media.__typename === 'Video') {
    return <Video className={className} video={media} {...props} />;
  }

  return null;
};

export default Media;
