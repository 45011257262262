import { useEffect, useState } from 'react';

interface sizeState {
  width: number;
  height: number;
}

const useWindowSize = (): sizeState => {
  // Is server side
  const isSSR = typeof window === 'undefined';

  const [windowSize, setWindowSize] = useState<sizeState>({
    width: isSSR ? 390 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  });

  const changeWindowSize = () =>
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize);

    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, []);

  return windowSize;
};

export default useWindowSize;
