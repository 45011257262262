import { gql } from 'graphql-request';

export const BASE_SITE_FRAGMENT = gql`
  fragment baseSiteValues on SiteDefaults {
    id
    partnerId
    siteType
    title
    description
    locationCoordinates {
      lat
      lng
    }
    locationName
  }
`;

export const GENERIC_SITE_FRAGMENT = gql`
  fragment genericSiteValues on GenericSite {
    ...baseSiteValues
  }
`;

export const WORKER_SUMMARY_FRAGMENT = gql`
  fragment workerSummaryValues on WorkersSummary {
    workerAmount
    averageAge
    womanPercentage
    numberOfChildren
    trainings {
      title
      percentageCompleted
    }
  }
`;

export const FARM_SITE_FRAGMENT = gql`
  fragment farmValues on Farm {
    size
    numberOfFarmers
    productiveSize
    directAccessToWater
    accessToSanitationFacilities
    workersSummary {
      ...workerSummaryValues
    }
    ownersWorkersSummary {
      ...workerSummaryValues
    }
    additionalRawMaterials {
      title
    }
    ...baseSiteValues
  }
  ${WORKER_SUMMARY_FRAGMENT}
`;

export const MINE_SITE_FRAGMENT = gql`
  fragment mineValues on Mine {
    ...baseSiteValues
  }
`;

export const FOREST_SITE_FRAGMENT = gql`
  fragment forestValues on Forest {
    ...baseSiteValues
  }
`;

export const COLLECTION_SITE_FRAGMENT = gql`
  fragment collectionSiteValues on CollectionSite {
    ...baseSiteValues
  }
`;

export const ORIGIN_SITE_FRAGMENT = gql`
  fragment originSiteValues on OriginSite {
    ...baseSiteValues
  }
`;

export const PLANT_SITE_FRAGMENT = gql`
  fragment plantValues on Plant {
    ...baseSiteValues
  }
`;

export const PROCESSING_SITE_FRAGMENT = gql`
  fragment processingSiteValues on ProcessingSite {
    ...baseSiteValues
  }
`;

export const FACTORY_SITE_FRAGMENT = gql`
  fragment factoryValues on Factory {
    ...baseSiteValues
  }
`;

export const MANUFACTURING_SITE_FRAGMENT = gql`
  fragment manufacturingSiteValues on ManufacturingSite {
    ...baseSiteValues
  }
`;

export const WAREHOUSE_SITE_FRAGMENT = gql`
  fragment warehouseValues on Warehouse {
    ...baseSiteValues
  }
`;

export const HANDLING_SITE_FRAGMENT = gql`
  fragment handlingSiteValues on HandlingSite {
    ...baseSiteValues
  }
`;

export const GROCERY_STORE_SITE_FRAGMENT = gql`
  fragment groceryStoreValues on GroceryStore {
    ...baseSiteValues
  }
`;

export const DISTRIBUTION_SITE_FRAGMENT = gql`
  fragment distributionSiteValues on DistributionSite {
    ...baseSiteValues
  }
`;

export const SITE_WITHOUT_CLUSTER_FRAGMENT = gql`
  fragment siteWithoutClusterValues on Site {
    ... on GenericSite {
      ...genericSiteValues
    }
    ... on Farm {
      ...farmValues
    }
    ... on Mine {
      ...mineValues
    }
    ... on Forest {
      ...forestValues
    }
    ... on CollectionSite {
      ...collectionSiteValues
    }
    ... on OriginSite {
      ...originSiteValues
    }
    ... on Plant {
      ...plantValues
    }
    ... on ProcessingSite {
      ...processingSiteValues
    }
    ... on Factory {
      ...factoryValues
    }
    ... on ManufacturingSite {
      ...manufacturingSiteValues
    }
    ... on Warehouse {
      ...warehouseValues
    }
    ... on HandlingSite {
      ...handlingSiteValues
    }
    ... on GroceryStore {
      ...groceryStoreValues
    }
    ... on DistributionSite {
      ...distributionSiteValues
    }
  }
  ${BASE_SITE_FRAGMENT}
  ${GENERIC_SITE_FRAGMENT}
  ${FARM_SITE_FRAGMENT}
  ${MINE_SITE_FRAGMENT}
  ${FOREST_SITE_FRAGMENT}
  ${COLLECTION_SITE_FRAGMENT}
  ${ORIGIN_SITE_FRAGMENT}
  ${PLANT_SITE_FRAGMENT}
  ${PROCESSING_SITE_FRAGMENT}
  ${FACTORY_SITE_FRAGMENT}
  ${MANUFACTURING_SITE_FRAGMENT}
  ${WAREHOUSE_SITE_FRAGMENT}
  ${HANDLING_SITE_FRAGMENT}
  ${GROCERY_STORE_SITE_FRAGMENT}
  ${DISTRIBUTION_SITE_FRAGMENT}
`;

export const SITE_CLUSTER_FRAGMENT = gql`
  fragment siteClusterValues on SiteCluster {
    ...baseSiteValues
    centroidCoordinates {
      lat
      lng
    }
    sites {
      edges {
        node {
          ...siteWithoutClusterValues
        }
      }
      count
    }
    sitesSummary {
      workersAmount
      additionalRawMaterialTitles
    }
  }
`;

export const SITE_FRAGMENT = gql`
  fragment siteValues on Site {
    ... on SiteCluster {
      ...siteClusterValues
    }
    ...siteWithoutClusterValues
  }
  ${SITE_CLUSTER_FRAGMENT}
  ${SITE_WITHOUT_CLUSTER_FRAGMENT}
`;
