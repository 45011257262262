import { useContext } from 'react';
import { ImpactClaimsContext } from 'components/Contexts';
import { ImpactClaim, ImpactClaimsType } from 'types/types';

const useImpactClaims = (): {
  selectedClaim?: ImpactClaim;
  selectedClaimId?: string;
  toggleImpactClaim: (impactClaim: string) => void;
  setSelectedClaimId: (ImpactClaim: string) => void;
  impactClaims: ImpactClaimsType;
  handleCloseClaim: () => void;
} => {
  return useContext(ImpactClaimsContext);
};

export default useImpactClaims;
